import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "shared-title",
  templateUrl: "./shared-title.component.html",
  styleUrls: ["./shared-title.component.scss"],
})
export class SharedTitleComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() hasBackButton: boolean;

  constructor(private location: Location) {}

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}
