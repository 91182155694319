import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { LayoutService } from './core/services/layout/layout.service';
import { PermissionService } from './core/services/permission/permission.service';
import { SettingService } from './setting/services/setting/setting.service';
import { UserService } from './user/services/user/user.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private environment = environment;

  constructor(
    public userService: UserService,
    public layoutService: LayoutService,
    public permissionService: PermissionService,
    private settingsService: SettingService,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.checkDevice();
    });
  }

  async checkDevice() {
    if (this.platform.is('ios')) {
      const result = await this.settingsService.settingsIOS();
      if (environment.version === result[0].value) {
        localStorage.setItem('hide-student-fields-ios', 'true');
      } else {
        localStorage.setItem('hide-student-fields-ios', 'false');
      }
    }
  }
}
