import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControlStatus, Validators } from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'shared-form-error',
  templateUrl: './shared-form-error.component.html',
  styleUrls: ['./shared-form-error.component.scss'],
})
export class SharedFormErrorComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() messages: Partial<Record<keyof typeof Validators, string>>;
  @Input() isChangeAllowed: boolean = false;
  errorName: String | null;
  @Output() invalidEvent = new EventEmitter<boolean>();

  constructor(public translations: TranslationsLayoutService) {}

  ngOnInit() {
    this.control.statusChanges.subscribe((value: FormControlStatus) => {
      if (
        value === 'INVALID' &&
        this.control.errors !== null &&
        this.control.dirty
      ) {
        this.errorName = Object.keys(this.control.errors)[0];
        this.invalidEvent.emit(true);
      } else if (value === 'VALID') {
        this.errorName = null;
        this.invalidEvent.emit(false);
      }
    });
  }
}
