import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { IonSearchbar, SearchbarCustomEvent } from '@ionic/angular';
import { SharedFormSelectOptionModel } from '../../../core/types/interfaces/shared-form-select-option.model';

@Component({
  selector: 'shared-form-combobox',
  templateUrl: './shared-form-combobox.component.html',
  styleUrls: ['./shared-form-combobox.component.scss'],
})
export class SharedFormComboboxComponent implements OnInit, AfterViewInit {
  // @ViewChild('selectedName') selectedName: ElementRef<HTMLInputElement>;
  @ViewChild('searchbar') ionSerchbar: IonSearchbar;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() otherControlName: string;
  @Input() filterControlName: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() otherTitle: string;
  @Input() otherPlaceholder: string;
  @Input() helpText: string;
  @Input() options: Array<SharedFormSelectOptionModel>;
  resultOptions: Array<SharedFormSelectOptionModel> = [];
  // private selectedOptionName: string;
  optionsVisible: boolean = false;
  disableInputChanges: boolean = false;
  // ionSerchbarInput: any;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.resultOptions = this.options;
    // this.init();
    const selectedOption = this.options.find((option) => {
      return (
        option.code === this.parentFormGroup.controls[this.controlName].value
      );
    });

    if (selectedOption) this.selectOption(selectedOption);
  }

  // async init() {
  //   requestAnimationFrame(async () => {
  //     this.ionSerchbarInput = await this.ionSerchbar.getInputElement();
  //   });
  // }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  selectOption(option: SharedFormSelectOptionModel) {
    this.toggleOptions();

    this.resultOptions = [];

    this.disableInputChanges = true;

    this.ionSerchbar.value = option.name;
    this.parentFormGroup.controls[this.controlName].setValue(option.code);
    this.optionsVisible = false;

    setTimeout(() => {
      this.disableInputChanges = false;
    }, 1000);
  }

  // searchbarIsFocused() {
  //   console.log(this.ionSerchbarInput, document.activeElement);
  //   if (typeof this.ionSerchbarInput === 'undefined') return true;
  //   else return this.ionSerchbarInput === (document.activeElement as any);
  // }

  searchOptions(value: string | undefined) {
    if (this.disableInputChanges) return;

    if (typeof value === 'undefined') {
      // this.resultOptions = this.options;
      this.resultOptions = [];
      return;
    }
    const regex = new RegExp(this.escapeRegExp(value), 'gi');

    this.resultOptions = this.options.filter((option) => {
      // return option.name.split(event.detail.value as string).length > 1;
      return option.name.match(regex);
    });
  }

  escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  showOptions() {
    this.optionsVisible = true;
  }

  toggleOptions() {
    this.optionsVisible = !this.optionsVisible;
  }

  filterOptions(option: SharedFormSelectOptionModel) {
    if (!this.filterControlName) return true;

    return (
      this.parentFormGroup.controls[this.filterControlName].value ===
      option.group
    );
  }
}
