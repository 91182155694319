import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  isMobile = true;
  // private _isToShowMenus = true;
  $isToShowMenus = new BehaviorSubject<boolean>(true);
  isToShowMenus$ = this.$isToShowMenus.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakPointsObserver();
  }

  breakPointsObserver() {
    // Utilizado 992px para seguir tamanho lg do ion-grid
    this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
  }

  // hideMenus() {
  //   console.log('hideMenus');
  //   this._isToShowMenus = false;
  // }
  // showMenus() {
  //   console.log('showMenus');
  //   this._isToShowMenus = true;
  // }

  // get isToShowMenus() {
  //   return this._isToShowMenus;
  // }
}
