import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private platform: Platform) {
    this.platform.ready().then(() => {
      this.requestATT();
    });
  }

  async requestATT() {
    if (this.platform.is('ios')) {
      const response = await AppTrackingTransparency.getStatus();

      if (response.status === 'notDetermined') {
        await AppTrackingTransparency.requestPermission();
      }
    }
  }
}
