import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SharedFormSelectOptionModel } from '../../../core/types/interfaces/shared-form-select-option.model';

@Component({
  selector: 'shared-form-select',
  templateUrl: './shared-form-select.component.html',
  styleUrls: ['./shared-form-select.component.scss'],
})
export class SharedFormSelectComponent implements OnInit, AfterViewInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() helpText: string;
  invalid: boolean = false;
  @Input() options: Array<SharedFormSelectOptionModel>;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
