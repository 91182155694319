import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from './services/notification/notification.service';
import { NotificationPopOverComponent } from './components/notification-pop-over/notification-pop-over.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    SharedModule,
  ],
  providers: [NotificationService],
  declarations: [NotificationPopOverComponent],
  exports: [NotificationPopOverComponent],
})
export class NotificationSharedModule {}
