import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ListSettingsGQL, SettingsIosGQL } from 'src/generated/graphql';

@Injectable()
export class SettingService {
  constructor(
    private listSettings: ListSettingsGQL,
    private settingsIosGQL: SettingsIosGQL
  ) {}

  async list() {
    const result = await firstValueFrom(
      this.listSettings.fetch(undefined, { context: { isToLoad: true } })
    );

    return result.data.settings;
  }

  async settingsIOS() {
    const result = await firstValueFrom(
      this.settingsIosGQL.fetch(undefined, { context: { isToLoad: false } })
    );

    return result.data.settingsIOS;
  }
}
