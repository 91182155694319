import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'shared-form-autocomplete',
  templateUrl: './shared-form-autocomplete.component.html',
  styleUrls: ['./shared-form-autocomplete.component.scss'],
})
export class SharedFormAutocompleteComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() helpText: string;
  @Input() toolTipText: string;
  @Input() state = '';
  invalid: boolean = false;
  options: google.maps.places.AutocompletePrediction[] = [];

  private googleAutocompleteService =
    new google.maps.places.AutocompleteService();

  ngOnInit() {}

  async searchAddress(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    if (!value) return;

    const response: google.maps.places.AutocompleteResponse =
      await this.googleAutocompleteService.getPlacePredictions({
        input: value,
        componentRestrictions: { country: 'br' },
        types: ['(cities)'],
      });

    let resultFiltered = [];

    for (const prediction of response.predictions) {
      if (this.state) {
        if (
          this.state !== prediction.terms[prediction.terms.length - 2].value
        ) {
          continue;
        }
      }

      const description = `${prediction.terms[0].value}, ${
        prediction.terms[prediction.terms.length - 2].value
      }, ${prediction.terms[prediction.terms.length - 1].value}`;

      resultFiltered.push({ ...prediction, description });
    }
    this.options = resultFiltered;
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
