import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloOptions } from './core/graphql/apollo-options/apollo-options.service';
import { LoadingLayoutService } from './core/services/loading/loading-layout.service';
import { ToastService } from './core/services/toast/toast.service';

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useClass: ApolloOptions,
      deps: [HttpLink, ToastService, LoadingLayoutService],
    },
  ],
  exports: [ApolloModule],
})
export class GraphQLModule {}
