import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingLayoutService {
  constructor(private loadingController: LoadingController) {}

  async create() {
    const instance = await this.loadingController.create({
      cssClass: '--ion-loading',
      spinner: null,
      message: ' ',
    });

    await instance.present();
    return instance;
  }
}
