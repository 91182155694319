import { Component, Input, OnInit } from '@angular/core';
import { HostUser, StudentUser, User } from 'src/generated/graphql';
import { UserBioInput } from '../../types/interfaces/user-bio-input';

@Component({
  selector: 'user-header-bio',
  templateUrl: './user-header-bio.component.html',
  styleUrls: ['./user-header-bio.component.scss'],
})
export class UserHeaderBioComponent implements OnInit {
  @Input() userHeaderBioInput: UserBioInput;
  constructor() {}

  ngOnInit() {}
}
