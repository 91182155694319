import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "creditCardMask",
})
export class CreditCardMaskPipe implements PipeTransform {
  transform(plainCreditCard: string | undefined, visibleDigits: number = 4): string {
    if (!plainCreditCard) return "";

    let maskedSection = plainCreditCard.slice(0, -visibleDigits);
    let visibleSection = plainCreditCard.slice(-visibleDigits);
    return maskedSection.replace(/./g, "*") + visibleSection;
  }
}
