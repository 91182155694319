import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserModalCroppieAvatarComponent } from './components/modals/user-modal-croppie-avatar/user-modal-croppie-avatar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../shared/shared.module';
import { UserBioComponent } from './components/user-bio/user-bio.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { UserHeaderBioComponent } from './components/user-header-bio/user-header-bio.component';
import { UserHiderDirective } from './directives/user-hider/user-hider.directive';
import { UserPopOverProfileComponent } from './components/user-pop-over-profile/user-pop-over-profile.component';
import { UserProfileProgressComponent } from './components/user-profile-progress/user-profile-progress.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    SharedModule,
    RoundProgressModule,
  ],
  declarations: [
    UserModalCroppieAvatarComponent,
    UserBioComponent,
    UserHeaderBioComponent,
    UserHiderDirective,
    UserPopOverProfileComponent,
    UserProfileProgressComponent,
  ],
  exports: [
    UserModalCroppieAvatarComponent,
    UserBioComponent,
    UserHeaderBioComponent,
    UserHiderDirective,
    UserPopOverProfileComponent,
    UserProfileProgressComponent,
  ],
  providers: [],
})
export class UserSharedModule {}
