import { Injectable } from '@angular/core';
import { OrderExperienceInput, OrderType } from '../../../../generated/graphql';

@Injectable()
export class FilterService {
  orders: OrderExperienceInput = { title: OrderType.Asc };
  filters: Array<number> = [];

  constructor() {}

  clear() {
    this.orders = {};
    this.filters = [];
  }
}
