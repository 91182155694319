import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CurrentStudentUserQuery,
  CurrentUserQuery,
  User,
} from 'src/generated/graphql';

export interface CurrentUserSessionView {
  currentUser: CurrentUserQuery['currentUser'];
}
export interface UpdateCurrentUserSessionView {
  currentUser: Partial<CurrentUserQuery['currentUser']>;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private $currentUserView: BehaviorSubject<CurrentUserSessionView | null> =
    new BehaviorSubject(null);
  currentUserView$ = this.$currentUserView.asObservable();

  currentStudentUser: CurrentStudentUserQuery['currentStudentUser'];
  socialURLs = {
    linkedIn: `https://www.linkedin.com/in/`,
    facebook: `https://www.facebook.com/`,
    instagram: `https://www.instagram.com/`,
  };

  constructor() {}

  getAvatarSrc(user: Pick<User, 'avatarSrc'>) {
    const src = user?.avatarSrc;

    if (src) return environment.mediaURL + (src ?? '');
    else {
      return '/assets/icons/avatar.svg';
    }
  }

  updateCurrentUser(user: UpdateCurrentUserSessionView | null) {
    if (user === null) {
      this.$currentUserView.next(null);
      return;
    }

    const current = this.getUser();

    this.$currentUserView.next({
      currentUser: {
        ...current?.currentUser!,
        ...user.currentUser,
      },
    });
  }

  getUser() {
    return this.$currentUserView.getValue();
  }

  hasUser(): boolean {
    let hasUser: boolean;
    if (this.$currentUserView.getValue() !== null) hasUser = true;
    else hasUser = false;

    return hasUser;
  }
}
