import { Component, OnInit } from "@angular/core";

@Component({
  selector: "core-layout-unauthenticated",
  templateUrl: "./core-layout-unauthenticated.component.html",
  styleUrls: ["./core-layout-unauthenticated.component.scss"],
})
export class CoreLayoutUnauthenticatedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
