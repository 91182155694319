import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'shared-form-toggle',
  templateUrl: './shared-form-toggle.component.html',
  styleUrls: ['./shared-form-toggle.component.scss'],
})
export class SharedFormToggleComponent implements OnInit {
  @Input() dataTestId?: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() helpText: string;
  @Input() links: Array<{ text: string; address: string }>;

  constructor(public translations: TranslationsLayoutService) {}

  ngOnInit() {}
}
