import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ExperienceService } from './services/experience/experience.service';
import { FilterService } from './services/filter/filter.service';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  providers: [ExperienceService, FilterService],
  declarations: [],
  exports: [],
})
export class ExperienceSharedModule {}
