import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { environment } from 'src/environments/environment';
import {
  RemoveMaterialGQL,
  UploadMaterialGQL,
} from '../../../../generated/graphql';
import { LoadingLayoutService } from '../../../core/services/loading/loading-layout.service';

@Component({
  selector: 'shared-form-input-file',
  templateUrl: './shared-form-input-file.component.html',
  styleUrls: ['./shared-form-input-file.component.scss'],
})
export class SharedFormInputFileComponent implements OnInit, AfterViewInit {
  @Input() experienceId: number;
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() helpTextHeader: string;
  @Input() helpTextFooter: string;
  selectedImage?: string;

  constructor(
    public translations: TranslationsLayoutService,
    private toastService: ToastService,
    private loadingLayoutService: LoadingLayoutService,
    private uploadMaterialGQL: UploadMaterialGQL,
    private removeMaterialGQL: RemoveMaterialGQL
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setDefaultValue();
    this.subscribeOnFormValueChange();
  }

  setDefaultValue() {
    this.selectedImage = this.parentFormGroup.controls[this.controlName].value;
  }

  subscribeOnFormValueChange() {
    const control = this.parentFormGroup.controls[this.controlName];

    control.valueChanges.subscribe((value: any) => {
      if (value === null || value === undefined) {
        this.selectedImage = undefined;
      } else {
        this.selectedImage = value;
      }
    });
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  async changedImage(event: Event) {
    let file = (event.target as HTMLInputElement)?.files?.[0];

    if (!file) return;

    if (file.size > 15000000) {
      this.toastService.error(
        `Tamanho máximo permitido 15mb, sua imagem tem ${(
          file.size / 1000000
        ).toFixed(1)}Mb`
      );

      return;
    }

    const loading = await this.loadingLayoutService.create();

    const imageURL = await this.uploadFIle(file);

    loading.dismiss();

    this.selectedImage = environment.mediaURL + imageURL;

    this.setFormValue();
  }

  setFormValue() {
    this.parentFormGroup.controls[this.controlName].setValue(
      this.selectedImage
    );
  }

  async uploadFIle(file: File) {
    const result = await firstValueFrom(
      this.uploadMaterialGQL.mutate(
        { file: file, experienceId: this.experienceId },
        { context: { useMultipart: true } }
      )
    );
    return result.data?.uploadMaterial.path;
  }

  async deleteImage() {
    await firstValueFrom(
      this.removeMaterialGQL.mutate({ experienceId: this.experienceId })
    );
    this.selectedImage = '';
    this.setFormValue();
  }
}
