import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RouterLayoutService {
  activeRouter: string;
  activeRouter$: BehaviorSubject<string>;
  // activeRouterAfter$: BehaviorSubject<string>;

  constructor(private router: Router) {
    this.activeRouter = this.router.url;
    this.activeRouter$ = new BehaviorSubject<string>(this.activeRouter);
    // this.activeRouterAfter$ = new BehaviorSubject<string>(this.activeRouter);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.activeRouter = event.url;
        this.activeRouter$.next(this.activeRouter);
      } else if (event instanceof NavigationEnd) {
        // this.activeRouterAfter$.next(event.url);
      }
    });
  }
}
