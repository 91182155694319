import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'shared-banner-security',
  templateUrl: './shared-banner-security.component.html',
  styleUrls: ['./shared-banner-security.component.scss'],
})
export class SharedBannerSecurityComponent implements OnInit {
  @Input() message: string | SafeHtml;

  constructor(public translations: TranslationsLayoutService) {}

  ngOnInit() {}
}
