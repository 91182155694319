import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  CreateEnrollmentGQL,
  CreateEnrollmentInput,
  EnrollmentGQL,
  ListEnrollmentsGQL,
  RemoveEnrollmentGQL,
  UpdateEnrollmentGQL,
  UpdateEnrollmentInput,
} from 'src/generated/graphql';

@Injectable()
export class EnrollmentService {
  constructor(
    private createEnrollmentGQL: CreateEnrollmentGQL,
    private listEnrollmentsGQL: ListEnrollmentsGQL,
    private removeEnrollmentGQL: RemoveEnrollmentGQL,
    private enrollmentGQL: EnrollmentGQL,
    private updateEnrollmentGQL: UpdateEnrollmentGQL
  ) {}

  async enrollment(id: number) {
    const result = await firstValueFrom(
      this.enrollmentGQL.fetch({ id }, { context: { isToLoad: true } })
    );

    return result.data?.enrollment;
  }

  async create(input: CreateEnrollmentInput) {
    const result = await firstValueFrom(
      this.createEnrollmentGQL.mutate(
        { input },
        { context: { isToLoad: true } }
      )
    );

    return result.data?.createEnrollment;
  }

  async list() {
    const result = await firstValueFrom(
      this.listEnrollmentsGQL.fetch(undefined, { context: { isToLoad: true } })
    );

    return result.data.enrollments;
  }

  async remove(id: number) {
    const result = await firstValueFrom(
      this.removeEnrollmentGQL.mutate({ id }, { context: { isToLoad: true } })
    );

    return result.data?.removeEnrollment;
  }

  async update(input: UpdateEnrollmentInput) {
    const result = await firstValueFrom(
      this.updateEnrollmentGQL.mutate(
        { input },
        { context: { isToLoad: true } }
      )
    );

    return result.data?.updateEnrollment;
  }
}
