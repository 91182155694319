import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { User } from 'src/generated/graphql';

@Component({
  selector: 'user-profile-progress',
  templateUrl: './user-profile-progress.component.html',
  styleUrls: ['./user-profile-progress.component.scss'],
})
export class UserProfileProgressComponent implements OnInit {
  progress: number = 50;
  @Input() user: User;
  constructor(public translations: TranslationsLayoutService) {}

  ngOnInit() {}
}
