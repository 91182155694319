import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../core/services/layout/layout.service';

@Component({
  selector: 'shared-progress-bar',
  templateUrl: './shared-progress-bar.component.html',
  styleUrls: ['./shared-progress-bar.component.scss'],
})
export class SharedProgressBarComponent implements OnInit {
  @Input() steps: number;
  @Input() currentStep: number;
  stepsArray: Array<number> = [];
  progress = 0;
  text = '';

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    this.stepsArray = Array.from({ length: this.steps }, (_, i) => i + 1);
    this.progress = (this.currentStep / this.steps) * 100;
    this.text = `Falta(m) ${
      this.steps - this.currentStep
    } experiência(s) vivida(s) para você desbloquear o seu Raio X Vocacional`;
  }

  test() {
    console.log('aaaaaa');
  }
}
