import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'shared-form-rating',
  templateUrl: './shared-form-rating.component.html',
  styleUrls: ['./shared-form-rating.component.scss'],
})
export class SharedFormRatingComponent implements OnInit {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  notes: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor() {}

  ngOnInit() {}

  selectNote(note: number) {
    this.parentFormGroup.controls[this.controlName].setValue(note);
  }
}
