import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'app-shared-modal-create-experience',
  templateUrl: './shared-modal-create-experience.component.html',
  styleUrls: ['./shared-modal-create-experience.component.scss'],
})
export class SharedModalCreateExperienceComponent implements OnInit {
  @Input() id: string;
  @Input() experienceId: number;

  constructor(
    public translations: TranslationsLayoutService,
    private router: Router,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  navigate(route: string) {
    this.router.navigate([route]);
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss(undefined, undefined, this.id);
  }
}
