import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { EnrollmentService } from './services/enrollment/enrollment.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    SharedModule,
  ],
  providers: [EnrollmentService],
  declarations: [],
  exports: [],
})
export class EnrollmentSharedModule {}
