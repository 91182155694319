import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { UserRole } from 'src/generated/graphql';
import { UserService } from '../../services/user/user.service';

@Directive({
  selector: '[userHider]',
})
export class UserHiderDirective implements AfterViewInit, OnInit {
  @Input() userHider: Array<UserRole>;
  private originalDisplay: string;
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.originalDisplay =
      this.elementRef.nativeElement.style.getPropertyValue('display');
    this.elementRef.nativeElement.style.setProperty('display', 'none');
  }

  ngAfterViewInit(): void {
    this.checkRole();
  }

  checkRole() {
    const user = this.userService.getUser()?.currentUser;
    let isToHide = true;

    if (typeof user?.role === 'undefined') return;

    isToHide = this.userHider.includes(user.role);

    if (isToHide) return;

    this.elementRef.nativeElement.style.setProperty(
      'display',
      this.originalDisplay
    );
  }
}
