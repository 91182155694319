import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RouterLayoutService } from '../../services/router/router-layout.service';
import { CoreHeaderComponent } from '../core-header/core-header.component';

@Component({
  selector: 'core-layout-authenticated',
  templateUrl: './core-layout-authenticated.component.html',
  styleUrls: ['./core-layout-authenticated.component.scss'],
})
export class CoreLayoutAuthenticatedComponent implements OnInit, AfterViewInit {
  @ViewChild(CoreHeaderComponent) coreHeader: CoreHeaderComponent;
  isOpenSideMenu: boolean = false;

  constructor(
    public routerLayoutService: RouterLayoutService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.coreHeader.sideMenuSubject.subscribe((res) => {
      this.isOpenSideMenu = res;
    });
  }
}
