import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { UserModalCroppieAvatarComponent } from 'src/app/user/components/modals/user-modal-croppie-avatar/user-modal-croppie-avatar.component';
import { UserService } from 'src/app/user/services/user/user.service';
import { UploadUserAvatarGQL } from 'src/generated/graphql';
import { UserBioInput } from '../../types/interfaces/user-bio-input';

@Component({
  selector: 'user-bio',
  templateUrl: './user-bio.component.html',
  styleUrls: ['./user-bio.component.scss'],
})
export class UserBioComponent implements OnInit, AfterViewInit {
  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;

  @Input() userBioInput: UserBioInput;

  public currentSrc?: string;
  constructor(
    public translations: TranslationsLayoutService,
    public responsivenessObserver: LayoutService,
    private modalController: ModalController,
    private uploadUserAvatarGQL: UploadUserAvatarGQL,
    private userService: UserService
  ) {}

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.currentSrc = this.avatarSrc;
  }

  get avatarSrc() {
    return this.userService.getAvatarSrc(this.userBioInput.user);
  }

  get socialURLs() {
    return this.userService.socialURLs;
  }

  selectNewAvatar() {
    this.inputFile.nativeElement.click();
  }

  inputChange(event: Event) {
    console.log(event);
    this.openModalCroppieAvatar(event);
  }

  async openModalCroppieAvatar(event: Event) {
    const target: HTMLInputElement = event.target as any;
    if (target.value === '') return;
    console.log(event);
    const modal = await this.modalController.create({
      component: UserModalCroppieAvatarComponent,
      cssClass: '--user-modal-croppie-avatar',
      id: 'userModalCroppieAvatar',
      componentProps: {
        id: 'userModalCroppieAvatar',
        imageEvent: event,
      },
    });

    await modal.present();

    await modal.onDidDismiss().then((res) => {
      if (!res.data) return;
      this.sendFile(res.data);
      this.inputFile.nativeElement.value = '';
    });
  }

  async sendFile(base64: string) {
    this.currentSrc = base64;
    requestAnimationFrame(async () => {
      const res = await fetch(base64);
      const blob = await res.blob();
      const file = new File([blob], '');
      await this.uploadUserAvatar(file, this.userBioInput.user.id);

      // if (typeof path === 'undefined') return;

      // const input: UpdateUserInput = {
      //   id: this.userBioInput.user.id,
      //   avatarSrc: path,
      // };

      // const updateStudentUserGQL = await this.updateUserGQL
      //   .mutate({
      //     input,
      //   })
      //   .toPromise();

      // if (
      //   this.userBioInput.user.id === this.userService.getUser()?.currentUser.id
      // ) {
      //   console.log('mesmo id pode atualizar');
      //   this.userService.updateCurrentUser({
      //     currentUser: { avatarSrc: path },
      //   });
      // } else {
      //   console.log('não faz nada');
      // }
    });
  }

  async uploadUserAvatar(file: File, userId?: number) {
    const result = await firstValueFrom(
      this.uploadUserAvatarGQL.mutate(
        { file: file, userId },
        { context: { useMultipart: true } }
      )
    );

    return result.data?.uploadUserAvatar.path;
  }
}
