export default {
  formError: {
    required: 'Esse campo é obrigatório',
    email: 'Email incorreto',
    cpf: 'Número do CPF inválido',
    pattern: 'Preenchimento incompleto ou inválido',
    minLength: 'Precisa ter pelo menos',
    maxLength: 'Pode ter no máximo',
    characters: 'caracteres',
    passwordNotMatch: 'Senhas não coincidem',
    emailNotMatch: 'E-mails não coincidem',
  },
  chat: {
    online: 'Online',
    enterYourMessageHere: 'Digite sua mensagem aqui',
    openedExperiences: 'Experiencias abertas',
    closedExperiences: 'Encerradas',
    callDescription: 'Chamada de vídeo com {{name}}, dia {{date}}',
    youGotAnInvite: 'Você recebeu um convite',
    doYouConfirm: 'Você confirma?',
    systemMessage: 'Mensagem do Sistema',
    theVideoCallingOptionWillBe:
      'A opção de chamada em vídeo será habilitada na data e hora informada!',
    userMustAcceptTheInvitation:
      'A opção de chamada em vídeo será habilitada na data e hora informada! {{name}} deve aceitar o convite.',
    accept: 'Aceitar',
    reject: 'Rejeitar',
    edit: 'Editar',
    confirm: 'Confirmar',
    joinTheCall: 'Entrar na chamada',
    date: 'Data',
    hour: 'Hora',
    videoCall: 'Chamada de vídeo',
    endExperience: 'Finalizar experiência',
    help: 'Ajuda',
    noChatSelected: 'Nenhum chat selecionado',
    selectOneToViewMessages: 'Selecione um para ver as mensagens.',
    videoCallScheduling: 'Agendamento de chamada em vídeo',
    enterToJumpLine: `<span class="pink">Enter</span> para enviar | <span class="pink">SHIFT + Enter</span> para adicionar linha`,
    thisIsYourStudentContactInformation:
      'Essas são as informações de contato do seu aluno. Use-ás para um contato mais direto ou falta de resposta no chat interno!',
    chooseTheBestDayAndTimeToSchedule:
      'Escolha o melhor dia e hora para agendar uma chamada em vídeo com {{name}}!',
  },
  sharedBanner: {
    loginPage: 'O que você quer ser agora?',
    registerPage:
      'Descubra experiências profissionais incríveis, no Diagnóstico de Especialidade!',
    recoverPasswordPage: 'Descubra uma nova profissão!',
    commonQuestionsPage: 'Estamos aqui para te ajudar',
    serviceTicketPage: 'Estamos aqui para te ajudar',
  },
  coreHeader: {
    welcome: 'Bem vindo, {{name}}!',
  },
  authCallToRegister: {
    title: 'Ainda não tem cadastro?',
    description: `Descubra um mundo de novas possibilidades e experiências incríveis
    <br>
    O cadastro é super simples!`,
  },
  sharedTitle: {
    loginPage: 'Entrar',
    registerPage: 'Cadastre-se!',
    registerPageSubtitle: '(* campos obrigatórios)',
    recoverPasswordPage: 'Esqueci minha senha',
    orManual: 'Ou manualmente',
    commonQuestionsPage: 'Perguntas frequentes',
    serviceTicketPage: 'Ticket de atendimento',
    approveExperiences: 'Aprovação de experiências',
    experiencesOpened: 'Experiências abertas',
    experiencesClosed:
      'Acesse aqui todo conteúdo didático e a gravação das suas experiências colecionadas',
    myEarningsPage: {
      headerTitle: 'Meus Ganhos',
      extractsTitle: 'Extrato',
    },
  },
  authLoginForm: {
    recoverPassword: 'Esqueci minha senha',
  },
  sharedFormTimeInput: {
    title: 'Horário',
    placeholder: '00:00',
  },
  sharedFormInput: {
    scheduleLimit: {
      title: 'Limite de usuários',
    },
    userOrCPForEmailTitle: 'Usuário, CPF ou e-mail',
    passwordTitle: 'Senha',
    confirmPasswordTitle: 'Confirme sua senha',
    name: {
      title: 'Digite o seu nome completo',
      placeholder: 'Nome',
    },
    cpf: {
      title: 'Qual é o seu CPF?',
      placeholder: 'CPF',
    },
    birthDate: {
      title: 'Selecione sua data de nascimento',
      placeholder: 'Data de nascimento',
    },
    password: {
      title: 'Digite uma senha',
      placeholder: 'Senha',
    },
    confirmPassword: {
      title: 'Confirme sua senha',
      placeholder: 'Senha',
    },
    neighborhood: {
      title: 'Digite o nome do seu bairro',
      placeholder: 'Bairro',
    },
    fullNameTitle: 'Nome completo',
    emailTitle: 'E-mail',
    confirmEmailTitle: 'Confirme seu e-mail',
    titleTitle: 'Título',
    titlePlaceholder: 'Desperte a curiosidade do seu convidado!',
    callTitle: 'Chamada',
    callPlaceholder: 'Aqui, uma frase de destaque para cativar seu público!',
    durationTitle: 'Duração da experiência (Em horas)',
    durationPlaceholder: 'Horas',
    studyAreaTitle: 'Área de estudo',
    studyAreaPlaceholder: 'Selecione a área de estudo da sua experiência',
    crmTitle: 'Registro profissional (Opcional)',
    crmHelpText:
      'Se você não tiver, não se preocupe. Nosso time irá entrar em contato para validar sua experiência profissional.',
    crmPlaceholder: 'CRM 99999/DF',
    agencyTitle: 'Agência',
    agencyPlaceholder: 'Agência s/ dígito',
    digitTitle: 'Dígito',
    digitPlaceholder: 'Dígito',
    accountTitle: 'Conta',
    accountPlaceholder: 'Conta s/ dígito',
    cardNumber: {
      title: 'Número do cartão',
      placeholder: 'Digite os números do seu cartão',
    },
    cvv: {
      title: 'CVV',
      placeholder: 'CVV',
      tollTipText: 'Últimos 3 números no verso do seu cartão',
    },
    year: {
      title: 'Ano',
      placeholder: 'Ano',
    },
    month: {
      title: 'Mês',
      placeholder: 'Mês',
    },
    cardFullName: {
      title: 'Nome(Como aparece no cartão)',
      placeholder: 'Digite seu nome',
    },
    email: {
      title: 'Digite seu e-mail',
      placeholder: 'E-mail',
    },
    confirmEmail: {
      title: 'Confirme seu e-mail',
      placeholder: 'E-mail',
    },
    phone: {
      title: 'Digite seu telefone',
      placeholder: 'Telefone',
    },
    firstName: {
      title: 'Nome',
      placeholder: 'Nome',
    },
    lastName: {
      title: 'Sobrenome',
      placeholder: 'Sobrenome',
    },
    fullName: {
      title: 'Nome',
      placeholder: 'João da Silva',
    },
    resetPassword: {
      title: 'Redefinir senha',
      placeholder: '********',
    },
    cep: {
      title: 'Digite seu CEP',
      placeholder: 'CEP',
    },
    street: {
      title: 'Digite sua rua',
      placeholder: 'Rua',
    },
    number: {
      title: 'Digite seu número',
      placeholder: 'Número',
    },
    complement: {
      title: 'Complemento',
      placeholder: 'Nenhum',
    },
    city: {
      title: 'Cidade',
      placeholder: 'São Paulo',
    },
    educationalInstitution: {
      title: 'Instituição de ensino',
      placeholder: 'UNIP - Universidade Paulista',
    },
    course: {
      title: 'Curso',
      placeholder: 'Análise de sistemas',
    },
    conclusionYear: {
      title: 'Ano de conclusão',
      placeholder: '2018',
    },
    linkedin: {
      placeholder: 'linkedin',
    },
    facebook: {
      placeholder: 'facebook',
    },
    instagram: {
      placeholder: 'instagram',
    },
    matter: {
      title: 'Assunto',
      placeholder: 'Descreva o motivo do atendimento',
    },
    date: {
      title: 'Selecione uma data',
      placeholder: 'Data',
    },
    address: {
      title: 'Qual seu endereço?',
      placeholder: 'Endereço',
    },
  },
  sharedFormToggle: {
    termsTitle: 'Aceito a Política de privacidade e termos de uso',
    termsHelpText: `Ao se cadastrar no Diagnóstico de Especialidade, você aceita os termos de uso e nossa política
    de privacidade que garantem sua segurança e de nossos parceiros durante todo o uso!`,
    termsLink: 'Termos de uso',
    policyLink: 'Política de privacidade',
    newsTitle: 'Aceito receber novidades por e-mail e SMS',
    newsHelpText: `Você ficará por dentro das novidades do Diagnóstico de Especialidade, experiências recomendadas,
    promoções e muito mais em seu e-mail e por SMS!
    Atenção: Mesmo não aceitando, você continuará recebendo notificações importantes da plataforma em seus e-mail!`,
    yes: 'SIM',
    no: 'NÃO',
    contributeTitle: 'Contribuir como voluntário?',
    contributeHelpText: `O Diagnóstico de Especialidade realiza programas de voluntariado para intermediar a
    interação entre profissionais e jovens em situação de
    vulnerabilidade, em escolas públicas ou em instituições carentes.`,
    saveRevenuesTitle: 'Salvar essas informações para futuros cadastros?',
    saveRevenuesHelpText: `Ao marcar essa opção como "SIM", nós salvaremos os dados acima
    para uso futuro em outros cadastros e você poderá cadastrar
    novas experiências com muito mais agilidade!`,
    buyExperience: {
      title: 'Termos e condições',
      helpText:
        'Aceito os termos e condições de uso do Diagnóstico de Especialidade',
      link: 'Ler os <b>Termos e condições de uso do Diagnóstico de Especialidade</b>',
    },
    isApproved: {
      title: 'Está aprovado?',
      helpText:
        'Informa se esse anfitrião esteja aprovado para prover experiências.',
    },
  },
  sharedButton: {
    enter: 'Entrar',
    createRegister: 'Criar meu cadastro!',
    finishRegister: 'Tudo certo! Finalizar cadastro.',
    recoverPassword: 'Enviar e-mail de recuperação',
    updatePassword: 'Atualizar senha',
    liveExperience: 'Vivenciar experiência',
    login: {
      withApple: 'Login com Apple',
      withGoogle: 'Login com Google',
      withFacebook: 'Login com Facebook',
    },
    register: {
      withApple: 'Registrar com Apple',
      withGoogle: 'Registrar com Google',
      withFacebook: 'Registrar com Facebook',
    },
    commonQuestionsPage: 'Abrir um ticket de atendimento',
    supportServiceTicketFormComponent: 'Abrir um ticket de atendimento',
    save: 'Salvar',
  },
  coreSideMenu: {
    menu: 'Menu',
    close: 'Fechar',
    experience: 'Vivenciar',
    experiencesCollected: 'Experiências <br> gravadas',
    create: 'Criar',
    myExperiences: 'Minhas <br> experiências',
    myEarnings: 'Meus ganhos',
    help: 'Ajuda',
    approver: 'Aprovador',
    raiox: 'Raio-X Vocacional',
    blog: 'Blog',
  },
  sharedPopOverNotifications: {
    title: 'Notificações',
    clear: 'Limpar',
  },
  sharedPopOverProfile: {
    title: 'Perfil',
    liveExperience: 'Vivenciar experiência',
    createExperience: 'Criar experiência',
    registerInformation: 'Informações de cadastro',
    bankInformation: 'Informações bancárias',
    myExperiences: 'Minhas experiências',
    leave: 'Sair',
  },
  sharedNavigationButton: {
    liveExperience: 'Vivenciar experiência',
    createExperience: 'Criar experiência',
    approver: 'Aprovador',
  },
  sharedButtonExperience: {
    knowOurProposal: 'Conheça a nossa proposta',
    buy: 'Comprar',
    knowMore: 'Saiba mais',
    editProfile: 'Editar perfil',
    sendResetPassword: 'Enviar e-mail de redefinição de senha',
    registerNewExperience: 'Cadastre uma nova experiência',
    statusScreen: 'Tela de status',
    initialPage: 'Página inicial',
    back: 'Voltar',
    erase: 'Apagar',
    sellerFinishExperience: 'Sim, desejo finalizar a experiência!',
    buyerFinishExperience: 'Sim, desejo finalizar a experiência!',
    sellerRateExperience: 'Encerrar e enviar feedback',
    availableOncePaymentIdentified:
      'Disponível assim que identificarmos o pagamento',
    seeChatHistory: 'Ver histórico do Chat',
    goToChat: 'Ir para o Chat',
    save: 'Salvar',
  },
  experienceInputFilter: {
    whatDoYouWantToBeNow: 'O que você quer ser agora?',
  },
  experienceModalityTag: {
    presential: 'PRESENCIAL',
    online: 'ONLINE',
  },
  experienceCardSegment: {
    general: 'Geral',
    includedIn: 'Incluso',
    requirements: 'Requisitos',
    knowMore: 'Saiba mais',
  },
  experienceBannerVideo: {
    title: 'O que você quer ser agora?',
    description: `A Seren acredita no poder da conexão do conhecimento humano, através da experimentação vocacional em nossa
    plataforma.`,
    name: 'Dr. Augusto Cury',
    bio: 'Acesse o nosso canal e assista os nossos vídeos guia para utilizar o Diagnóstico de Especialidade',
  },
  homePage: {
    highlights: 'Destaques',
    recommended: 'Recomendadas',
    experiences: 'Experiências',
  },
  experienceFilter: {
    modality: 'Modalidade',
    clear: 'Limpar filtros',
    knowledgeArea: 'Áreas médicas',
  },
  experienceButtonFilter: {
    filterBy: 'Filtrar por',
  },
  sharedFormCombobox: {
    educationalInstitution: {
      title: 'Instituição de ensino',
      placeholder: 'UNIP - Universidade Paulista',
    },
  },
  sharedFormBannerImage: {
    bannerImage: 'Imagem de capa',
    helpTextHeader: `Clique ou arraste e solte sua imagem aqui`,
    helpTextFooter: `Faça upload da sua foto de capa`,
    highlightedText: 'Destaque',
  },
  sharedFormTextarea: {
    description: 'Descrição',
    placeholderDescription: 'Construa um texto rico em informações!',

    serviceTicketPage: {
      title: 'Descrição',
      placeholder: 'Descreva o ocorrido',
    },

    bio: {
      title: 'Descreva suas experiências profissionais',
      placeholder: 'Bio',
    },
  },
  sharedFormInsertList: {
    domains: {
      title: 'Domínios',
      placeholder: '@exemplo.com',
    },
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    add: 'Adicionar',
    keyWordsTitle: 'Palavras-chave',
    keyWordsPlaceholder: 'Digite e adicione',
    keyWordsHelpText:
      'Palavras chaves são essenciais para o seu convidado te achar mais rápido e aumentar as suas vendas.',
    includedTitle:
      'Para sua experiência ser completa, é importante que o conteúdo possua tanto material técnico e teórico como demonstrações práticas da sua rotina profissional',
    includedPlaceholder: 'Digite e adicione',
    requirementTitle:
      'Isso é interessante para você que quer definir um público alvo específico, por exemplo, quero falar apenas com alunos de universidades.',
    requirementPlaceholder: 'Digite e adicione',
    listHeader: 'Clique e arraste para posicionar',
    language: {
      title: 'Idioma',
      placeholder: 'Digite e adicione',
      helpText:
        'Começe a digitar o idioma, selecione e adicione a lista e a seguir, selecione a fluência sobre esse idioma.',
    },
  },
  experienceStepHeader: {
    helpText: 'Clique em algum ponto para voltar',
    helpTextButton: 'Me ajuda!',
    create: {
      stepBasic: {
        title: 'Cadastre uma experiência incrível',
        helpText: 'Ajuda',
      },
      stepDetails: {
        title: 'Detalhes da experiência',
        helpText: 'Ajuda',
      },
      stepModality: {
        title: 'Valores de cada modalidade',
        helpText: 'Ajuda',
      },
      stepIncluded: {
        title: 'O que está incluso',
        helpText: 'Ajuda',
      },
      stepRequirements: {
        title: 'Requisitos',
        helpText: 'Ajuda',
      },
      stepBilling: {
        title: 'Informações e faturamento',
        helpText: 'Ajuda',
      },
      stepCheckout: {
        title: 'Tudo certo!',
        helpText: 'Ajuda',
      },
    },
    buy: {
      stepModality: {
        title: 'Sua experiência a três passos',
        helpText: 'Ajuda',
      },
      stepPaymentMethod: {
        title: 'Formas de pagamento',
        helpText: 'Ajuda',
      },
      stepBilling: {
        title: 'Finalizar pagamento',
        helpText: 'Ajuda',
      },
      stepCheckout: {
        title: 'Tudo certo!',
        helpText: 'Ajuda',
      },
    },
  },
  sharedFormStepFooter: {
    btnDeleteText: 'Apagar',
    btnNext: 'Preencha as informações',
    btnNextValid: 'Tudo certo, avançar',
    publishExperience: 'Publicar experiência',
    liveExperience: 'Viver experiência',
  },
  sharedFormModalityCheckbox: {
    title: 'Modalidade',
    helpText: 'Clique para marcar uma ou duas modalidades para sua experiência',
    presential: 'Presencial',
    online: 'Online',
  },
  sharedFormAddressInput: {
    cityAndState: {
      title: 'Informe a cidade',
      placeholder: 'Balneário Camboriú',
    },
    neighborhood: {
      title: 'Informe o seu bairro',
      placeholder: 'Centro',
    },
    state: {
      title: 'Qual seu estado?',
      placeholder: 'Estado',
    },
  },
  sharedFormSelect: {
    knowledgeAreaTitle: 'Área de conhecimento',
    knowledgeAreaPlaceholder:
      'Selecione a área de conhecimento da sua experiêmcia',
    knowledgeAreaOtherTitle: `Outra área de conhecimento`,
    knowledgeAreaOtherPlaceholder: `Você selecionou a área de conhecimento como "Outros", escreva aqui!`,
    studyAreaTitle: 'Área de estudo',
    studyAreaPlaceholder: 'Selecione a área de estudo da sua experiência',
    bankTitle: 'Banco',
    bankPlaceholder: 'Selecione o nome ou número do seu banco',
    typeTitle: 'Tipo',
    typePlaceholder: 'Tipo da sua conta Bancária',
    experience: {
      title: 'Experiência',
      placeholder: 'Selecione uma experiência',
    },
    hostUser: {
      title: 'Anfitrião',
      placeholder: 'Selecione um anfitrião',
    },
    installment: {
      title: 'Parcelas',
      placeholder: 'Número de parcelas',
    },
    genre: {
      title: 'Qual é seu gênero?',
      placeholder: 'Gênero',
    },
    courseType: {
      title: 'Qual é o seu curso?',
      placeholder: 'Curso',
    },
    hostEducationLevel: {
      title: 'Grau de escolaridade',
      placeholder: 'Graduado',
    },

    hostUserItinerary: {
      title: 'Itinerário',
      placeholder: 'Ciencias exatas e da terra',
    },

    hostUserOccupationArea: {
      title: 'Área de Atuação',
      placeholder: 'Adminstração',
    },

    specialization: {
      title:
        'Já tem uma ideia da especialização que quer seguir? Deixe-nos saber',
      placeholder: 'Especialização',
    },

    hostSpecialization: {
      title: 'Qual é a sua especialização?',
      placeholder: 'Especialização',
    },

    studentUserItinerary: {
      title: 'Itinerários de Interesse:',
      placeholder: 'Administração, Ciências',
    },

    studentEducationLevel: {
      title: 'Grau de escolaridade',
      placeholder: '1° ano do Ensino Médio',
    },
    problemType: {
      title: 'Tipo de problema',
      placeholder: 'Selecione o tipo de problema',
    },
    ethnicity: {
      title: 'Qual é sua etnia?',
      placeholder: 'Etinia',
    },
    familyIncome: {
      title: 'Qual é sua renda familiar?',
      placeholder: 'Renda',
    },
    state: {
      title: 'Selecione seu estado',
      placeholder: 'Estado',
    },
  },
  sharedFormModalityPrice: {
    title: `Valor da experiência`,
    helpText: `Valor a receber após taxas e impostos:`,
    placeholder: `R$`,
    presential: `Presencial`,
    online: `Online`,
    disabled: `Modalidade não selecionada na etapa anterior`,
  },
  sharedFormCpfInput: {
    title: 'CPF',
    helpText: `<b>Atenção:</b> Esse dado <b>NÃO</b> pode ser alterado posteriormente!`,
    placeholder: '477.774.566-22',
    inPayment: {
      title: 'CPF do títular',
      placeholder: 'Digite seu CPF',
    },
  },
  sharedFormDateInput: {
    title: 'Data de nascimento',
    placeholder: '01/01/1990',
    schedule: {
      title: 'Data do evento',
      placeholder: '00/00/0000',
    },
    residenceTestAt: {
      title:
        'Já sabe a data que irá realizar a prova de Residência? Deixe-nos saber',
      placeholder: 'Data',
    },
  },
  experienceCreateStepBilling: {
    billingRegistration: 'Cadastro de faturamento',
  },
  experienceCreateStepCheckout: {
    checkout: 'Checkout',
    checkoutDescription: `Confirme todos os dados que você preencheu para sua experiência. Se precisar
    editar alguma informação, volte ao passo correspondente. <b> Se estiver tudo certo é
    só clicar em <span style="color: var(--ion-color-primary");">"Publicar experiência"</span> </b>`,
    initialInformation: 'Informações iniciais',
    title: 'Título',
    call: 'Chamada',
    description: 'Descrição',
    keyWords: 'Palavras-chave',
    experienceDetails: 'Detalhes da experiência',
    modality: 'Modalidade',
    cityOrAddress: 'Cidade ou Endereço',
    experienceDuration: 'Duração da experiência',
    knowledgeArea: 'Área de conhecimento',
    studyArea: 'Área de estudo',
    contribute: 'Contribuir como voluntário?',
    presential: 'Presencial',
    online: 'Online',
    hours: 'horas',
    yes: 'SIM',
    no: 'NÃO',
    modalityValues: 'Valores de cada modalidade',
    modalityNotSelected: 'Modalidade não selecionada',
    whatIsIncluded: 'O que está incluso',
    requirements: 'Requisitos',
    billingInformation: 'Informações de faturamento',
    cpf: 'CPF',
    professionalRegister: 'Registro profissional',
    fullName: 'Nome completo',
    bank: 'Banco',
    agencyAndDigit: 'Agência - Digito',
    accountAndDigit: 'Conta - Digito',
    type: 'Tipo',
    saveInformation: 'Salvar essas informações?',
    printVersion: 'Versão para impressão',
    perfect: 'Perfeito',
    publish: 'Publicar',
  },
  sharedFormModalityRadio: {
    online: 'Online',
    presential: 'Presencial',
  },
  experienceViewKeyWords: {
    seeAll: 'Ver todas',
  },
  experienceViewRate: {
    ratings: 'Classificações',
  },
  experienceDetailsPage: {
    knowExperience: 'Conheça um pouco mais sobre essa experiência incrível',
    whatIsIncluded: 'O que está incluso',
    requirements: 'Requisitos',
    offeredBy: 'Experiência oferecida por',
    installmentPrice: 'Em 6x <br> {{price}} sem juros',
    deleteExperience: 'Apagar essa experiência',
  },
  experienceStepHeaderStatus: {
    helpTextButton: 'Me ajuda!',
    installmentPrice: 'Preço parcelado',
    register: 'Cadastro',
    billingInformation: 'Informações de faturamento',
    medcelAnalysis: 'Análise Diagnóstico de Especialidade',
    approved: 'Aprovado',
    reproved:
      'Experiência reprovada, enviamos um e-mail com instruções para correção',
  },
  sharedFormToggleList: {
    requirements: {
      title:
        'Essa experiência possui alguns pré-requisitos, por favor, confirme que você cumpri com todos eles!',
      helpText: `O Seller selecionou alguns pré-requisitos que você deve cumprir para que consiga acompanhar o desenvolvimento desta experiência.`,
    },
  },
  sharedFormModalityRadioWithImage: {
    title: 'Modalidade',
    helpText: 'Selecione a modalidade que deseja realizar essa experiência',
    map: {
      title: `Essa é a <span class="highlight">cidade</span> da experiência presencial.`,
      helptext: `Os detalhes de contato e endereços serão enviados pelo fornecedor no chat, ao finalizar a compra.`,
    },
  },
  experienceBannerInfo: {
    installmentPrice: 'Em 6x <br> {{price}} sem juros',
  },
  sharedBannerSecurity: {
    experienceBuyPage: `Todos os nossos pagamentos são garantidos pela pagar.me, uma empresa
    <span style="color: var(--ion-color-success)">Stone Co. </span><a style="color:var(--ion-color-tertiary)">Clique aqui</a> e conheça mais!`,
    experienceCreatePage: `Todas as compras de experiências são processadas pela Pagar.me, uma empresa
    <span style="color: var(--ion-color-success)">Stone Co. </span><a style="color:var(--ion-color-tertiary)">Clique aqui</a> e conheça mais!`,

    myEarningsPage: `Todos os nossos pagamentos são garantidos pela pagar.me, uma empresa
    <span style="color: var(--ion-color-success)">Stone Co. </span><a style="color:var(--ion-color-tertiary)">Clique aqui</a> e conheça mais!`,
  },
  sharedFormPaymentsRadio: {
    creditCard: 'Cartão de crédito',
    pix: 'PIX',
    bankBillet: 'Boleto bancário',
  },
  sharedFormPaymentNewCreditCard: {},
  experienceBuyStepCheckout: {
    checkout: 'Checkout',
    checkoutDescription: `Confirme todos os dados para sua experiência. Se precisar editar alguma informação,
    volte ao passo correspondente.<b> Se estiver tudo certo é só clicar em
    <span style="color: var(--ion-color-primary");">"Viver experiência"</span> </b>`,
    experience: 'Experiência',
    resume: 'Resumo',
    payment: 'Pagamento',
    cpf: 'CPF do títular',
    email: 'E-mail',
    phone: 'Telefone',
    pix: 'PIX',
    bankBillet: 'Boleto bancário',
    values: 'Valores',
    tax: 'Taxa administrativa',
    installments: 'Parcelas',
    total: 'Total',
    printVersion: 'Versão para impressão',
    perfect: 'Perfeito',
    confirm: 'Confirmar',
  },
  userProfileSegmentMobile: {
    profileInfo: 'Informações de cadastro',
    bankInfo: 'Informações bancárias',
    myExperiences: 'Minhas experiências',
  },
  userProfileSegmentDesktop: {
    profileInfo: 'Informações de cadastro',
    bankInfo: 'Informações bancárias',
    myExperiences: 'Minhas experiências',
  },
  userProfileProgress: {
    title: 'Progresso do seu perfil',
    helpText:
      'Mantenha seu perfil completo e atualizado para aproveitar todos os recursos da plataforma',
  },
  userInfo: {
    basicInformation: 'Informações básicas',
    userAndContact: 'Usuário e contato',
    academic: 'Acadêmico',
    schooling: 'Educacional',
    socialNetworks: 'Redes sociais',
    linkedinLink: 'https://www.linkedin.com/in/',
    facebookLink: 'https://www.facebook.com/',
    instagramLink: 'https://www.instagram.com/',
  },
  sharedFormFooter: {
    cancel: 'Cancelar',
    save: 'Salvar',
  },
  userBankInfo: {
    billingInfo: 'Informações para faturamento',
  },
  userExperiences: {
    experience: 'Experiência',
    information: 'Informações',
    actions: 'Ações',
    sales: 'Vendas',
    rank: 'Classificação',
    openedExperiences: 'Experiências abertas',
    gains: 'Ganhos',
  },
  sharedModalCreateExperience: {
    title: 'Recebemos sua experiência e temos certeza de que ela é incrível!',
    description:
      'Precisamos somente analisar alguns pontos e em breve ela estará disponível para todo mundo!',
    helpText:
      'Você pode acompanhar o andamento pela tela de status ou pelo seu e-mail!',
  },
  sharedModalDeleteExperience: {
    title: 'Atenção, você está prestes a APAGAR uma experiência!',
    helpText:
      'Para continuar, digite o nome da sua experiência no campo abaixo',
    inputHelpText: 'Digite: {{experienceName}}',
  },
  sharedModalSellerFinishExperience: {
    title: 'Esse é o fim da sua experiência com {{buyerName}}?',
    description: `Muito obrigado por nos ajudar a levar a <b>experimentação profissional</b>
    para que mais pessoas conheçam a profissão que desejam seguir!`,
    helpText:
      'Você deseja mesmo encerrar essa experiência com {{buyerName}}? Lembrando que essa ação não poderá ser desfeita!',
    buttonConfirmHelpText:
      '{{buyerName}} será notificado e deverá confirmar o encerramento.',
  },
  sharedModalRateExperience: {
    title: 'O que você achou da interação com {{userName}}?',
    description:
      'Numa escala de 1 a 10, o quanto você se identificou com esta especialização?',
  },
  sharedModalPaidExperience: {
    title:
      'Incrível! Muito obrigado por acreditar no Diagnóstico de Especialidade e no seu potencial!',
    description:
      'Estamos processando o seu pagamento, você receberá um e-mail quando estiver tudo certo e poderá iniciar sua experiência imediatamente!',
    helpText:
      'O chat para iniciar a experiência com seu instrutor, estará disponível assim que identificarmos o pagamento!',
    buttonChatHelpText: 'CHAT com o instrutor',
  },
  sharedModalBuyerFinishExperience: {
    title: '{{sellerName}}, está finalizando essa experiência!',
    description: `Essa experiência com a {{sellerName}} está chegando ao fim! Muito obrigado por conhecer a <b>experimentação profissional</b> e descobrir profissões incríveis!`,
    helpText: 'Você deseja mesmo encerrar essa experiência com {{sellerName}}?',
    buttonConfirmHelpText: 'Essa ação não poderá ser desfeita!',
    buttonSupport: 'Suporte',
  },
  sharedModalContact: {
    title: 'Recebemos o seu contato!',
    description:
      'Nossa equipe já está cuidando de tudo e logo entrará em contato com você para solucionar seu problema! Fique sempre de olho em sua caixa de entrada ou na lixeira!',
  },
  sharedModalRememberRating: {
    title: 'Bem vindo de volta!',
    description:
      'Você realizou uma ou mais experiência vocacional recentemente. Não deixe de dar seu feedback acessando o menu "Notificações" no canto superior direito da tela. Sua avaliação é muito importante para nós.',
  },
  commonQuestionsPage: {
    bannerLink: 'Abrir um ticket de atendimento',
    bodyDivisionTitle: 'Parceiros',
    footerTitle: 'Não encontrou o que estava procurando?',
  },
  adminRowExperience: {
    experience: 'Experiência',
    information: 'Informações',
    actions: 'Ações',
    value: 'Valor <br> Presencial | Online',
    city: 'Cidade',
    duration: 'Duração',
    voluntary: 'Voluntário',
    edit: 'Editar',
    enable: 'Habilitar',
    disable: 'Desabilitar',
    disapprove: 'Reprovar',
    approve: 'Aprovar',
  },
  experienceCardDiscoveryComponent: {
    discovery: 'Descubra novas experiências',
    info: 'Temos diversas experimentações profissionais para você descobrir a profissão que ama!',
  },
  userEarningsSummaryComponent: {
    available: {
      title: 'Disponível para saque',
      body: `Os saques são automáticos e realizados na conta bancária cadastrada em seu perfil em até 15 dias pela
      pagar.me!<br /><br />
      Uma taxa administrativa de saque de R$3,15 é cobrada em cada transação realizada. Clique aqui e saiba
      mais!
      `,
      dateTitle: 'Data da próxima transação:',
    },
    unavailable: {
      awaiting: {
        title: 'Aguardando liberação',
        body: `Esse valor é referente a vendas que estão aguardando processamento bancário. Após a identificação do
        pagamento, o valor é disponibilizado para saque.`,
      },
      transfered: {
        title: 'Transferidos',
        body: `Esse é o valor total transferido para sua conta bancária até hoje.`,
      },
    },
  },
  studentDeleteModal: {
    admin: {
      title: 'Atenção, você está prestes a APAGAR um Estudante!',
      helpText: 'Para continuar, digite <b>"confirmar"</b> no campo abaixo',
    },
    student: {
      title: 'Atenção, você está prestes a APAGAR sua conta!',
      helpText: 'Para continuar, digite sua <b>"senha"</b> no campo abaixo',
    },
  },
  studentInfo: {
    btnDeleteAccount: 'Apagar conta',
  },
  sharedFormAutocomplete: {
    city: {
      title: 'Digite o nome da sua cidade',
      placeholder: 'Cidade',
    },
  },
};
