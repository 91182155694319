import { Component, OnInit } from "@angular/core";

@Component({
  selector: "shared-form-required-icon",
  templateUrl: "./shared-form-required-icon.component.html",
  styleUrls: ["./shared-form-required-icon.component.scss"],
})
export class SharedFormRequiredIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
