import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import {
  CreateExperienceGQL,
  CreateExperienceInput,
  FilterExperienceInput,
  InputMaybe,
  ListExperiencesGQL,
  ListExperiencesQuery,
  OrderExperienceInput,
  RaioXGQL,
  RecommendedExperienceGQL,
  RemoveExperienceGQL,
  UpdateExperienceGQL,
  UpdateExperienceInput,
} from 'src/generated/graphql';

@Injectable()
export class ExperienceService {
  constructor(
    private listExperienceGQL: ListExperiencesGQL,
    private createExperienceGQL: CreateExperienceGQL,
    private updateExperienceGQL: UpdateExperienceGQL,
    private removeExperienceGQL: RemoveExperienceGQL,
    private recommendedExperienceGQL: RecommendedExperienceGQL,
    private raioXGQL: RaioXGQL
  ) {}

  async create(input: CreateExperienceInput) {
    await firstValueFrom(
      this.createExperienceGQL.mutate(
        { input },
        { context: { isToLoad: true } }
      )
    );
  }

  async update(input: UpdateExperienceInput) {
    await firstValueFrom(
      this.updateExperienceGQL.mutate(
        { input },
        { context: { isToLoad: true } }
      )
    );
  }

  async list(
    filters?: InputMaybe<FilterExperienceInput> | undefined,
    orders?: InputMaybe<OrderExperienceInput> | undefined
  ): Promise<ListExperiencesQuery['experiences']> {
    const result = await firstValueFrom(
      this.listExperienceGQL.fetch(
        { filters, orders },
        { context: { isToLoad: true } }
      )
    );

    return result.data.experiences;
  }
  // async list(
  //   input?: InputMaybe<FilterExperienceInput> | undefined
  // ): Promise<ListExperiencesQuery['experiences']> {
  //   const result = await firstValueFrom(
  //     this.listExperienceGQL.fetch({ input }, { context: { isToLoad: true } })
  //   );

  //   return result.data.experiences;
  // }

  async removeExperience(id: number) {
    const result = await firstValueFrom(
      this.removeExperienceGQL.mutate({ id }, { context: { isToLoad: true } })
    );

    return result.data?.removeExperience;
  }

  async recommendedExperience() {
    const result = await firstValueFrom(
      this.recommendedExperienceGQL.fetch({}, { context: { isToLoad: true } })
    );

    return result.data.recommendedExperiences;
  }

  // async REMOVER_searchList(
  //   input?: InputMaybe<SearchFilterExperienceInput> | undefined
  // ): Promise<SearchListExperiencesQuery['SearchExperiences']> {
  //   const result = await firstValueFrom(
  //     this.searchListExperienceGQL.fetch(
  //       { input },
  //       { context: { isToLoad: true } }
  //     )
  //   );

  //   return result.data.SearchExperiences;
  // }

  async getRaioX() {
    const result = await firstValueFrom(
      this.raioXGQL.fetch(
        {},
        { context: { isToLoad: true, isToHideMessage: true } }
      )
    );

    return result.data.raiox;
  }
}
