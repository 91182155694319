import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-button-whatsapp',
  templateUrl: './shared-button-whatsapp.component.html',
  styleUrls: ['./shared-button-whatsapp.component.scss'],
})
export class SharedButtonWhatsappComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  navigate() {
    window.open('https://api.whatsapp.com/send?phone=5521989956483', '_blank');
  }
}
