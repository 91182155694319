import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ɵɵtrustConstantResourceUrl,
} from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { SharedFormSelectOptionModel } from '../../../core/types/interfaces/shared-form-select-option.model';

@Component({
  selector: 'shared-form-insert-select',
  templateUrl: './shared-form-insert-select.component.html',
  styleUrls: ['./shared-form-insert-select.component.scss'],
})
export class SharedFormInsertSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('selectedName') selectedName: ElementRef<HTMLInputElement>;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() otherControlName: string;
  @Input() filterControlName: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() otherTitle: string;
  @Input() otherPlaceholder: string;
  @Input() helpText: string;

  list: Array<SharedFormSelectOptionModel> = [];

  private _options: Array<SharedFormSelectOptionModel>;
  get options() {
    return this._options;
  }
  @Input() set options(options) {
    this._options = options;
    // if (typeof options !== undefined && options.length >= 1) {
    //   const selectedOption = this.getSelectedOption();
    //   // console.log('selectedOption', selectedOption);
    //   if (selectedOption) this.selectOption(selectedOption);
    // }
  }
  optionsVisible: boolean = false;

  constructor() {}

  removeItem(index: number) {
    this.list.splice(index, 1);
    this.parentFormGroup.controls[this.controlName].setValue(this.list);
  }
  ngOnInit() {
    // this.subscribeOnFormValueChange();
  }

  insertOption(option: SharedFormSelectOptionModel) {
    if (typeof this.selectedName === 'undefined') return;

    this.selectedName.nativeElement.value = option.name;
    const control = this.parentFormGroup.controls[this.controlName];
    this.optionsVisible = false;
    this.list.push(option);
    control.setValue(this.list);
  }

  // insertItem() {
  //   if (this.options) {
  //     this.list.push({
  //       value: this.formGroup.controls['newItem'].name,
  //       option: this.options[0].code,
  //     });
  //   } else {
  //     this.list.push(this.formGroup.controls['newItem'].value);
  //   }
  //   this.parentFormGroup.controls[this.controlName].setValue(this.list);
  //   this.formGroup.controls['newItem'].setValue('');
  // }

  // selectOption(option: SharedFormSelectOptionModel) {
  //   if (typeof this.selectedName === 'undefined') return;

  //   this.selectedName.nativeElement.value = option.name;
  //   const control = this.parentFormGroup.controls[this.controlName];
  //   control.setValue(option.code, {
  //     emitEvent: false,
  //   });
  //   this.optionsVisible = false;
  // }
  ngAfterViewInit() {}

  getSelectedOption() {
    const selectedOption = this.findOptionByCode(
      this.parentFormGroup.controls[this.controlName].value
    );
    return selectedOption;
  }

  findOptionByCode(code: SharedFormSelectOptionModel['code']) {
    //todo use code as key  in select typing to improve this find
    const selectedOption = this.options.find((option) => {
      return (
        option.code === code
        // option.code ===
      );
    });
    return selectedOption;
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  toggleOptions() {
    this.optionsVisible = !this.optionsVisible;
  }

  // getSelectedName() {
  //   const control = this.parentFormGroup.controls[this.controlName];
  //   return this.findOptionByCode(control.value)?.name;
  // }

  // filterOptions(option: SharedFormSelectOptionModel) {
  //   if (!this.filterControlName) return true;

  //   return (
  //     this.parentFormGroup.controls[this.filterControlName].value ===
  //     option.group
  //   );
  // }

  // subscribeOnFormValueChange() {
  //   const control = this.parentFormGroup.controls[this.controlName];
  //   control.valueChanges.subscribe(
  //     (code: SharedFormSelectOptionModel['code']) => {
  //       if (code === null) {
  //         this.selectOption({ code: code, name: '' });
  //         return;
  //       }
  //       const selectedOption = this.findOptionByCode(code);
  //       if (typeof selectedOption === 'undefined') return;

  //       this.selectOption(selectedOption);
  //     }
  //   );
  // }
}
