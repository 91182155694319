import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { EnrollmentSharedModule } from '../enrollment/enrollment-shared.module';
import { SettingService } from './services/setting/setting.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    SharedModule,
    EnrollmentSharedModule,
  ],
  providers: [SettingService],
})
export class SettingSharedModule {}
