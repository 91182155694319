import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { RaioXQuery } from 'src/generated/graphql';

@Component({
  selector: 'app-shared-modal-raio-x',
  templateUrl: './shared-modal-raio-x.component.html',
  styleUrls: ['./shared-modal-raio-x.component.scss'],
})
export class SharedModalRaioXComponent implements OnInit {
  @Input() id: string;
  @Input() raioX: RaioXQuery['raiox'];
  progress = 0;

  constructor(
    public translations: TranslationsLayoutService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.progress =
      (this.raioX.experiencesLived.length / this.raioX.raioXMinEnrollment) *
      100;
  }

  closeModal(data: any = undefined) {
    this.modalController.dismiss(data, undefined, this.id);
  }
}
