import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "shared-button-experience",
  templateUrl: "./shared-button-experience.component.html",
  styleUrls: ["./shared-button-experience.component.scss"],
})
export class SharedButtonExperienceComponent implements OnInit {
  @Input() text: string;
  @Input() iconSrc: string;

  constructor() {}

  ngOnInit() {}
}
