import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { EnrollmentSharedModule } from '../enrollment/enrollment-shared.module';
import { ExperienceSharedModule } from '../experience/experience-shared.module';
import { NotificationSharedModule } from '../notification/notification-shared.module';
import { SharedModule } from '../shared/shared.module';
import { UserSharedModule } from '../user/user-shared.module';
import { CoreHeaderComponent } from './components/core-header/core-header.component';
import { CoreLayoutAuthenticatedComponent } from './components/core-layout-authenticated/core-layout-authenticated.component';
import { CoreLayoutUnauthenticatedComponent } from './components/core-layout-unauthenticated/core-layout-unauthenticated.component';
import { CoreSideMenuComponent } from './components/core-side-menu/core-side-menu.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    SharedModule,
    TranslateModule.forChild(),
    UserSharedModule,
    NotificationSharedModule,
    EnrollmentSharedModule,
    ExperienceSharedModule,
  ],
  providers: [],
  declarations: [
    CoreLayoutUnauthenticatedComponent,
    CoreLayoutAuthenticatedComponent,
    CoreHeaderComponent,
    CoreSideMenuComponent,
  ],
  exports: [
    CoreLayoutUnauthenticatedComponent,
    CoreLayoutAuthenticatedComponent,
    CoreHeaderComponent,
    CoreSideMenuComponent,
  ],
})
export class CoreModule {}
