import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "shared-button",
  templateUrl: "./shared-button.component.html",
  styleUrls: ["./shared-button.component.scss"],
})
export class SharedButtonComponent implements OnInit {
  @Input() text: string;
  @Input() iconSrc: string;
  @Input() iconPosition: "left" | "right" = "left";

  constructor() {}

  ngOnInit() {}
}
