import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import ptBr from 'src/assets/i18n/pt-br';

export function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = ''
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
        (objectToTransform: any, [key, value]: any) => {
          objectToTransform[key] = transformObjectToPath(
            key,
            value,
            concatIfExistsPath(path, suffix)
          );

          return objectToTransform;
        },
        {} as T
      )
    : (concatIfExistsPath(path, suffix) as T);
}

@Injectable({
  providedIn: 'root',
})
export class TranslationsLayoutService extends GenericClass<typeof ptBr>() {
  // @ts-ignore
  unique = this.constructor['ɵprov'];
  constructor(
    private platform: Platform,
    private translateService: TranslateService
  ) {
    super();
    Object.assign(this, transformObjectToPath('', ptBr));
    // console.log(this.unique);

    this.platform.ready().then(() => {
      this.translateService.setDefaultLang('pt-br');
      this.translateService.use('pt-br');
    });
  }

  changeLanguage(language: 'pt-br' | 'en-us'): void {
    this.translateService.use(language);
  }
}
