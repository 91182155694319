import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** List of cpfs that can be registered as a student */
export type AllowedStudent = {
  __typename?: 'AllowedStudent';
  cpf: Scalars['String'];
  id: Scalars['Int'];
  studentUser?: Maybe<StudentUser>;
};

export type AllowedStudentRelationInput = {
  cpf: Scalars['String'];
};

export type AuthLogin = {
  __typename?: 'AuthLogin';
  accessToken: Scalars['String'];
};

export type AuthLoginByIntegration = {
  __typename?: 'AuthLoginByIntegration';
  url: Scalars['String'];
};

export type CreateEnrollmentInput = {
  schedule: GraphQlRelationInput;
  viewType?: InputMaybe<EnrollmentViewType>;
  wasPresent?: InputMaybe<Scalars['Boolean']>;
};

export type CreateEnrollmentRecordedInput = {
  experience: GraphQlRelationInput;
};

export type CreateExperienceInput = {
  averageSalary: Scalars['Float'];
  description: Scalars['String'];
  imageSrcIndex: Scalars['Float'];
  imageSrcs: Array<Scalars['String']>;
  isHighlighted: Scalars['Boolean'];
  itinerary: GraphQlRelationInput;
  jobScore: Scalars['Float'];
  material?: InputMaybe<Scalars['String']>;
  specialization: GraphQlRelationInput;
  subtitle: Scalars['String'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type CreateHostUserInput = {
  bio: Scalars['String'];
  birthDate: Scalars['DateTime'];
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: HostUserEducationLevel;
  educationalInstitution: Scalars['String'];
  isVolunteer: Scalars['Boolean'];
  linkedin: Scalars['String'];
  neighborhood: Scalars['String'];
  specialization: GraphQlRelationInput;
  state: Scalars['String'];
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateItemMondayInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  problemType: MondayProblemType;
  subject: Scalars['String'];
};

export type CreateItineraryInput = {
  description: Scalars['String'];
  imageSrc: Scalars['String'];
  name: Scalars['String'];
};

export type CreateJwtJitsiInput = {
  schedule?: InputMaybe<GraphQlRelationInput>;
};

export type CreateScheduleInput = {
  endDate: Scalars['DateTime'];
  experience: GraphQlRelationInput;
  hostUser: GraphQlRelationInput;
  limit?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  videoURL: Scalars['String'];
};

export type CreateSettingInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateSpecializationInput = {
  name: Scalars['String'];
};

export type CreateStudentUserByIntegrationInput = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  cpf: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  telephone: Scalars['String'];
};

export type CreateStudentUserInput = {
  allowedStudent?: InputMaybe<AllowedStudentRelationInput>;
  birthDate: Scalars['DateTime'];
  cep?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  courseType: StudentUserCourseType;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  instagram?: InputMaybe<Scalars['String']>;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
  residenceTestAt?: InputMaybe<Scalars['DateTime']>;
  specialization?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateStudentUserTrialInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  gender: UserGender;
  integrationId?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<UserRole>;
};

export type DeleteStudentUserInput = {
  id: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  ratingComment?: Maybe<Scalars['String']>;
  schedule: Schedule;
  studentUser: StudentUser;
  viewType: EnrollmentViewType;
  wasPresent?: Maybe<Scalars['Boolean']>;
};

export enum EnrollmentViewType {
  Live = 'LIVE',
  Recorded = 'RECORDED'
}

export type Experience = {
  __typename?: 'Experience';
  averageSalary: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  imageSrcIndex: Scalars['Float'];
  imageSrcs: Array<Scalars['String']>;
  isHighlighted: Scalars['Boolean'];
  itinerary: Itinerary;
  jobScore: Scalars['Float'];
  material?: Maybe<Scalars['String']>;
  rating?: Maybe<ExperienceRating>;
  schedules: Array<Schedule>;
  specialization: Specialization;
  subtitle: Scalars['String'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  videoUrl?: Maybe<Scalars['String']>;
};


export type ExperienceSchedulesArgs = {
  filters?: InputMaybe<FilterExperienceSchedulesInput>;
};

export type ExperienceRating = {
  __typename?: 'ExperienceRating';
  count: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FilterBooleanInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type FilterDateInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FilterExperienceInput = {
  description?: InputMaybe<FilterStringInput>;
  isHighlighted?: InputMaybe<FilterBooleanInput>;
  itinerary?: InputMaybe<FilterNumberInput>;
  schedules?: InputMaybe<FilterBooleanInput>;
  subtitle?: InputMaybe<FilterStringInput>;
  tags?: InputMaybe<FilterStringInput>;
  title?: InputMaybe<FilterStringInput>;
};

export type FilterExperienceSchedulesInput = {
  endDate?: InputMaybe<FilterDateInput>;
  hostUser?: InputMaybe<GraphQlRelationInput>;
  startDate?: InputMaybe<FilterDateInput>;
};

export type FilterNotificationInput = {
  readed?: InputMaybe<FilterBooleanInput>;
  type?: InputMaybe<FilterNotificationType>;
};

export type FilterNotificationType = {
  operation: FilterOperation;
  value?: InputMaybe<Array<NotificationType>>;
};

export type FilterNumberInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FilterOperation {
  Any = 'any',
  ArrayContainedBy = 'arrayContainedBy',
  ArrayContains = 'arrayContains',
  ArrayOverlap = 'arrayOverlap',
  Between = 'between',
  Equal = 'equal',
  Ilike = 'ilike',
  In = 'in',
  IsNull = 'isNull',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  Like = 'like',
  MoreThan = 'moreThan',
  MoreThanOrEqual = 'moreThanOrEqual',
  Not = 'not',
  Raw = 'raw'
}

export type FilterScheduleInput = {
  endDate?: InputMaybe<FilterDateInput>;
  hostUser?: InputMaybe<GraphQlRelationInput>;
  startDate?: InputMaybe<FilterDateInput>;
};

export type FilterStringInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type GraphQlRelationInput = {
  id: Scalars['Int'];
};

export type HostUser = {
  __typename?: 'HostUser';
  bio: Scalars['String'];
  birthDate: Scalars['DateTime'];
  cep?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: HostUserEducationLevel;
  educationalInstitution: Scalars['String'];
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  instagram?: Maybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
  isVolunteer: Scalars['Boolean'];
  itinerary: Itinerary;
  linkedin?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  schedules?: Maybe<Array<Schedule>>;
  /** Especialização */
  specialization: Specialization;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: User;
};

export enum HostUserEducationLevel {
  College = 'COLLEGE',
  Doctor = 'DOCTOR',
  Elementary = 'ELEMENTARY',
  Graduate = 'GRADUATE',
  Master = 'MASTER',
  Postgraduate = 'POSTGRADUATE',
  Secondary = 'SECONDARY',
  Uninformed = 'UNINFORMED'
}

export type Integration = {
  __typename?: 'Integration';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  domains?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: Array<User>;
};

export type ItemMonday = {
  __typename?: 'ItemMonday';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Itinerary = {
  __typename?: 'Itinerary';
  description: Scalars['String'];
  experiences: Array<Experience>;
  hostUsers: Array<HostUser>;
  id: Scalars['Int'];
  imageSrc: Scalars['String'];
  name: Scalars['String'];
  studentUsers: Array<StudentUser>;
};

export type JitsiDestroyRoom = {
  jwt: Scalars['String'];
  roomName: Scalars['String'];
};

export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  type: LogType;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum LogType {
  Login = 'LOGIN'
}

export type LoginIntegrationInput = {
  secret: Scalars['String'];
};

export type LoginUserByIntegrationInput = {
  email: Scalars['String'];
};

export type LoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum MondayProblemType {
  Error = 'ERROR',
  Improvement = 'IMPROVEMENT',
  New = 'NEW',
  PersonalData = 'PERSONAL_DATA',
  Technical = 'TECHNICAL',
  Upgrade = 'UPGRADE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createEnrollment: Enrollment;
  createEnrollmentRecorded: Enrollment;
  createExperience: Experience;
  createHostUser: HostUser;
  createItemMonday: ItemMonday;
  createItinerary: Itinerary;
  createJWTJitsi: Scalars['String'];
  createSchedule: Schedule;
  createSetting: Setting;
  createSpecialization: Specialization;
  createStudentUser: StudentUser;
  createStudentUserByIntegration: StudentUserByIntegration;
  createStudentUserTrial: StudentUserByIntegration;
  finishSchedule: Scalars['Boolean'];
  jitsiDestroyRoom: Scalars['String'];
  loginIntegration: AuthLogin;
  loginUser: AuthLogin;
  loginUserByIntegration: AuthLoginByIntegration;
  removeEnrollment: Enrollment;
  removeExperience: Experience;
  removeHostUser: HostUser;
  removeMaterial: UpdateResult;
  removeSchedule: Schedule;
  removeStudentUser: StudentUser;
  removeUser: User;
  requestPasswordRecovery: Scalars['Boolean'];
  updateEnrollment: Enrollment;
  updateExperience: Experience;
  updateHostUser: HostUser;
  updateNotification: Notification;
  updateSchedule: Schedule;
  updateSetting: Setting;
  updateStudentUser: StudentUser;
  updateUser: User;
  updateUserByIntegration: UserByIntegration;
  uploadImage: File;
  uploadMaterial: File;
  uploadUserAvatar: File;
  user: User;
};


export type MutationCreateEnrollmentArgs = {
  createEnrollmentInput: CreateEnrollmentInput;
};


export type MutationCreateEnrollmentRecordedArgs = {
  createEnrollmentRecordedInput: CreateEnrollmentRecordedInput;
};


export type MutationCreateExperienceArgs = {
  createExperienceInput: CreateExperienceInput;
};


export type MutationCreateHostUserArgs = {
  createHostUserInput: CreateHostUserInput;
};


export type MutationCreateItemMondayArgs = {
  createItemMondayInput: CreateItemMondayInput;
};


export type MutationCreateItineraryArgs = {
  createItineraryInput: CreateItineraryInput;
};


export type MutationCreateJwtJitsiArgs = {
  createJWTJitsiInput: CreateJwtJitsiInput;
};


export type MutationCreateScheduleArgs = {
  createScheduleInput: CreateScheduleInput;
};


export type MutationCreateSettingArgs = {
  createSettingInput: CreateSettingInput;
};


export type MutationCreateSpecializationArgs = {
  createSpecializationInput: CreateSpecializationInput;
};


export type MutationCreateStudentUserArgs = {
  createStudentUserInput: CreateStudentUserInput;
};


export type MutationCreateStudentUserByIntegrationArgs = {
  args: CreateStudentUserByIntegrationInput;
};


export type MutationCreateStudentUserTrialArgs = {
  args: CreateStudentUserTrialInput;
};


export type MutationFinishScheduleArgs = {
  schedule: GraphQlRelationInput;
};


export type MutationJitsiDestroyRoomArgs = {
  jitsiDestroyRoom: JitsiDestroyRoom;
};


export type MutationLoginIntegrationArgs = {
  args: LoginIntegrationInput;
};


export type MutationLoginUserArgs = {
  loginUserInput: LoginUserInput;
};


export type MutationLoginUserByIntegrationArgs = {
  args: LoginUserByIntegrationInput;
};


export type MutationRemoveEnrollmentArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveExperienceArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveHostUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveMaterialArgs = {
  experienceId: Scalars['Int'];
};


export type MutationRemoveScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveStudentUserArgs = {
  deleteStudentUserInput: DeleteStudentUserInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRequestPasswordRecoveryArgs = {
  requestPasswordRecoveryInput: RequestPasswordRecoveryInput;
};


export type MutationUpdateEnrollmentArgs = {
  updateEnrollmentInput: UpdateEnrollmentInput;
};


export type MutationUpdateExperienceArgs = {
  updateExperienceInput: UpdateExperienceInput;
};


export type MutationUpdateHostUserArgs = {
  updateHostUserInput: UpdateHostUserInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationUpdateScheduleArgs = {
  updateScheduleInput: UpdateScheduleInput;
};


export type MutationUpdateSettingArgs = {
  updateSettingInput: UpdateSettingInput;
};


export type MutationUpdateStudentUserArgs = {
  updateStudentUserInput: UpdateStudentUserInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserByIntegrationArgs = {
  input: UpdateUserByIntegrationInput;
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadMaterialArgs = {
  experienceId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationUploadUserAvatarArgs = {
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationUserArgs = {
  createUserInput: CreateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  readed: Scalars['Boolean'];
  text: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<NotificationType>;
  url?: Maybe<Scalars['String']>;
  user: User;
};

export enum NotificationType {
  Default = 'DEFAULT',
  Rating = 'RATING'
}

export type OrderExperienceInput = {
  averageSalary?: InputMaybe<OrderType>;
  jobScore?: InputMaybe<OrderType>;
  title?: InputMaybe<OrderType>;
};

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Query = {
  __typename?: 'Query';
  SearchExperiences: Array<Experience>;
  currentHostUser: HostUser;
  currentStudentUser: StudentUser;
  currentUser: User;
  enrollment: Enrollment;
  enrollments: Array<Enrollment>;
  experience: Experience;
  experiences: Array<Experience>;
  findCollected: Experience;
  hostUser: HostUser;
  hostUsers: Array<HostUser>;
  itineraries: Array<Itinerary>;
  notifications: Array<Notification>;
  raiox: RaioX;
  recommendedExperiences: Array<Experience>;
  schedule: Schedule;
  schedules: Array<Schedule>;
  settings: Array<Setting>;
  settingsIOS: Array<Setting>;
  specializations: Array<Specialization>;
  studentUser: StudentUser;
  studentUsers: Array<StudentUser>;
};


export type QuerySearchExperiencesArgs = {
  filters?: InputMaybe<SearchFilterExperienceInput>;
};


export type QueryEnrollmentArgs = {
  id: Scalars['Int'];
};


export type QueryExperienceArgs = {
  id: Scalars['Int'];
};


export type QueryExperiencesArgs = {
  filters?: InputMaybe<FilterExperienceInput>;
  orders?: InputMaybe<OrderExperienceInput>;
};


export type QueryFindCollectedArgs = {
  id: Scalars['Int'];
};


export type QueryHostUserArgs = {
  id: Scalars['Int'];
};


export type QueryNotificationsArgs = {
  filters?: InputMaybe<FilterNotificationInput>;
};


export type QueryScheduleArgs = {
  id: Scalars['Int'];
};


export type QuerySchedulesArgs = {
  filters?: InputMaybe<FilterScheduleInput>;
};


export type QueryStudentUserArgs = {
  id: Scalars['Int'];
};

export type RaioX = {
  __typename?: 'RaioX';
  experiencesLived: Array<Enrollment>;
  latentAreas: Array<Scalars['String']>;
  raioXMinEnrollment: Scalars['Int'];
  youMayAlsoLike: Array<Experience>;
};

export type RequestPasswordRecoveryInput = {
  email: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  endDate: Scalars['DateTime'];
  enrollments: Array<Enrollment>;
  experience: Experience;
  hostUser: HostUser;
  id: Scalars['Int'];
  limit: Scalars['Float'];
  occurredEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  videoURL: Scalars['String'];
};

export type SearchFilterExperienceInput = {
  data?: InputMaybe<Array<FilterExperienceInput>>;
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Specialization = {
  __typename?: 'Specialization';
  experiences?: Maybe<Array<Experience>>;
  hostUser?: Maybe<Array<HostUser>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  studentUser?: Maybe<Array<StudentUser>>;
};

export type StudentUser = {
  __typename?: 'StudentUser';
  allowedStudent: AllowedStudent;
  birthDate?: Maybe<Scalars['DateTime']>;
  cep?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  courseType: StudentUserCourseType;
  enrollments: Array<Itinerary>;
  ethnicity: StudentUserEthnicity;
  facebook?: Maybe<Scalars['String']>;
  familyIncome: StudentUserFamilyIncome;
  hoursExperienced: Scalars['Float'];
  id: Scalars['Int'];
  instagram?: Maybe<Scalars['String']>;
  itineraries: Array<Itinerary>;
  linkedin?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  /** When the residence will occur. Prova de residência */
  residenceTestAt?: Maybe<Scalars['DateTime']>;
  /** Profissão preferida */
  specialization?: Maybe<Specialization>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: User;
};

export type StudentUserByIntegration = {
  __typename?: 'StudentUserByIntegration';
  birthDate?: Maybe<Scalars['DateTime']>;
  cpf: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  telephone: Scalars['String'];
};

export enum StudentUserCourseType {
  Graduation = 'Graduation',
  NotApplicable = 'NotApplicable',
  R1 = 'R1',
  R3 = 'R3',
  Revalidate = 'Revalidate',
  Titles = 'Titles'
}

export enum StudentUserEthnicity {
  Black = 'BLACK',
  Brown = 'BROWN',
  Indigenous = 'INDIGENOUS',
  NotSelected = 'NOT_SELECTED',
  NoDeclaration = 'NO_DECLARATION',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export enum StudentUserFamilyIncome {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  NotSelected = 'NOT_SELECTED'
}

export type UpdateEnrollmentInput = {
  id: Scalars['Int'];
  rating?: InputMaybe<Scalars['Int']>;
  ratingComment?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<EnrollmentViewType>;
  wasPresent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateExperienceInput = {
  averageSalary?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageSrcIndex?: InputMaybe<Scalars['Float']>;
  imageSrcs?: InputMaybe<Array<Scalars['String']>>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  itinerary?: InputMaybe<GraphQlRelationInput>;
  jobScore?: InputMaybe<Scalars['Float']>;
  material?: InputMaybe<Scalars['String']>;
  specialization?: InputMaybe<GraphQlRelationInput>;
  subtitle?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateHostUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  educationLevel?: InputMaybe<HostUserEducationLevel>;
  educationalInstitution?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  instagram?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isVolunteer?: InputMaybe<Scalars['Boolean']>;
  itinerary?: InputMaybe<GraphQlRelationInput>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  specialization?: InputMaybe<GraphQlRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UpdateUserInput>;
};

export type UpdateNotificationInput = {
  id: Scalars['Int'];
  readed: Scalars['Boolean'];
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  success: Scalars['Boolean'];
};

export type UpdateScheduleInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  experience?: InputMaybe<GraphQlRelationInput>;
  hostUser?: InputMaybe<GraphQlRelationInput>;
  id: Scalars['Int'];
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  videoURL?: InputMaybe<Scalars['String']>;
};

export type UpdateSettingInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentUserInput = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  courseType: StudentUserCourseType;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  id: Scalars['Int'];
  instagram?: InputMaybe<Scalars['String']>;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  residenceTestAt?: InputMaybe<Scalars['DateTime']>;
  specialization?: InputMaybe<GraphQlRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UpdateUserInput>;
};

export type UpdateUserByIntegrationInput = {
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  avatarSrc?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<UserGender>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  activateAccountToken?: Maybe<Scalars['String']>;
  avatarSrc?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  gender: UserGender;
  hostUser?: Maybe<HostUser>;
  id: Scalars['Int'];
  integration?: Maybe<Integration>;
  isActive: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  logs?: Maybe<Array<Log>>;
  name: Scalars['String'];
  notifications: Array<Notification>;
  password: Scalars['String'];
  resetPasswordToken?: Maybe<Scalars['String']>;
  role: UserRole;
  studentUser?: Maybe<StudentUser>;
  updatedAt: Scalars['DateTime'];
};

export type UserByIntegration = {
  __typename?: 'UserByIntegration';
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Uninformed = 'UNINFORMED'
}

export enum UserRole {
  Admin = 'ADMIN',
  Host = 'HOST',
  Integration = 'INTEGRATION',
  Student = 'STUDENT'
}

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename: 'AuthLogin', accessToken: string } };

export type RequestPasswordRecoveryMutationVariables = Exact<{
  input: RequestPasswordRecoveryInput;
}>;


export type RequestPasswordRecoveryMutation = { __typename?: 'Mutation', requestPasswordRecovery: boolean };

export type RemoveMaterialMutationVariables = Exact<{
  experienceId: Scalars['Int'];
}>;


export type RemoveMaterialMutation = { __typename?: 'Mutation', removeMaterial: { __typename?: 'UpdateResult', success: boolean } };

export type UploadMaterialMutationVariables = Exact<{
  file: Scalars['Upload'];
  experienceId: Scalars['Int'];
}>;


export type UploadMaterialMutation = { __typename?: 'Mutation', uploadMaterial: { __typename?: 'File', path: string } };

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'File', path: string } };

export type UploadUserAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type UploadUserAvatarMutation = { __typename?: 'Mutation', uploadUserAvatar: { __typename?: 'File', path: string } };

export type CreateEnrollmentRecordedMutationVariables = Exact<{
  input: CreateEnrollmentRecordedInput;
}>;


export type CreateEnrollmentRecordedMutation = { __typename?: 'Mutation', createEnrollmentRecorded: { __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } } };

export type CreateEnrollmentMutationVariables = Exact<{
  input: CreateEnrollmentInput;
}>;


export type CreateEnrollmentMutation = { __typename?: 'Mutation', createEnrollment: { __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } } };

export type EnrollmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EnrollmentQuery = { __typename?: 'Query', enrollment: { __typename?: 'Enrollment', id: number, createdAt: any, rating?: number | null, ratingComment?: string | null, schedule: { __typename?: 'Schedule', experience: { __typename?: 'Experience', title: string, id: number } } } };

export type ListEnrollmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEnrollmentsQuery = { __typename?: 'Query', enrollments: Array<{ __typename?: 'Enrollment', id: number, wasPresent?: boolean | null, viewType: EnrollmentViewType, schedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, occurredEndDate?: any | null, experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number } } }> };

export type RemoveEnrollmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveEnrollmentMutation = { __typename?: 'Mutation', removeEnrollment: { __typename: 'Enrollment' } };

export type UpdateEnrollmentMutationVariables = Exact<{
  input: UpdateEnrollmentInput;
}>;


export type UpdateEnrollmentMutation = { __typename?: 'Mutation', updateEnrollment: { __typename: 'Enrollment' } };

export type CreateExperienceMutationVariables = Exact<{
  input: CreateExperienceInput;
}>;


export type CreateExperienceMutation = { __typename?: 'Mutation', createExperience: { __typename?: 'Experience', id: number } };

export type ExperienceFindCollectedQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ExperienceFindCollectedQuery = { __typename?: 'Query', findCollected: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number, description: string, tags: Array<string>, material?: string | null, videoUrl?: string | null } };

export type ExperienceWithoutEnrollmentsQueryVariables = Exact<{
  id: Scalars['Int'];
  filterSchedule: FilterExperienceSchedulesInput;
}>;


export type ExperienceWithoutEnrollmentsQuery = { __typename?: 'Query', experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, jobScore: number, averageSalary: number, material?: string | null, videoUrl?: string | null, rating?: { __typename?: 'ExperienceRating', value?: number | null, count: number } | null, specialization: { __typename?: 'Specialization', id: number, name: string }, itinerary: { __typename?: 'Itinerary', id: number, name: string }, schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, occurredEndDate?: any | null, hostUser: { __typename?: 'HostUser', id: number, user: { __typename?: 'User', name: string, avatarSrc?: string | null } } }> } };

export type ExperienceQueryVariables = Exact<{
  id: Scalars['Int'];
  filterSchedule: FilterExperienceSchedulesInput;
}>;


export type ExperienceQuery = { __typename?: 'Query', experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, tags: Array<string>, jobScore: number, averageSalary: number, material?: string | null, videoUrl?: string | null, rating?: { __typename?: 'ExperienceRating', value?: number | null, count: number } | null, specialization: { __typename?: 'Specialization', id: number, name: string }, itinerary: { __typename?: 'Itinerary', id: number, name: string }, schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, occurredEndDate?: any | null, enrollments: Array<{ __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } }>, hostUser: { __typename?: 'HostUser', id: number, bio: string, user: { __typename?: 'User', name: string, avatarSrc?: string | null } } }> } };

export type ListExperiencesQueryVariables = Exact<{
  filters?: InputMaybe<FilterExperienceInput>;
  orders?: InputMaybe<OrderExperienceInput>;
}>;


export type ListExperiencesQuery = { __typename?: 'Query', experiences: Array<{ __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, jobScore: number, averageSalary: number, videoUrl?: string | null, material?: string | null, itinerary: { __typename?: 'Itinerary', name: string } }> };

export type RaioXQueryVariables = Exact<{ [key: string]: never; }>;


export type RaioXQuery = { __typename?: 'Query', raiox: { __typename?: 'RaioX', latentAreas: Array<string>, raioXMinEnrollment: number, youMayAlsoLike: Array<{ __typename?: 'Experience', id: number, title: string }>, experiencesLived: Array<{ __typename?: 'Enrollment', viewType: EnrollmentViewType, rating?: number | null, createdAt: any, schedule: { __typename?: 'Schedule', startDate: any, hostUser: { __typename?: 'HostUser', user: { __typename?: 'User', name: string } }, experience: { __typename?: 'Experience', id: number, title: string, specialization: { __typename?: 'Specialization', id: number, name: string }, itinerary: { __typename?: 'Itinerary', name: string } } } }> } };

export type RecommendedExperienceQueryVariables = Exact<{ [key: string]: never; }>;


export type RecommendedExperienceQuery = { __typename?: 'Query', recommendedExperiences: Array<{ __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number, description: string }> };

export type RemoveExperienceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveExperienceMutation = { __typename?: 'Mutation', removeExperience: { __typename: 'Experience' } };

export type UpdateExperienceMutationVariables = Exact<{
  input: UpdateExperienceInput;
}>;


export type UpdateExperienceMutation = { __typename?: 'Mutation', updateExperience: { __typename?: 'Experience', title: string } };

export type CreateHostUserMutationVariables = Exact<{
  input: CreateHostUserInput;
}>;


export type CreateHostUserMutation = { __typename?: 'Mutation', createHostUser: { __typename?: 'HostUser', id: number } };

export type CurrentHostSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentHostSchedulesQuery = { __typename?: 'Query', currentHostUser: { __typename?: 'HostUser', schedules?: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, experience: { __typename?: 'Experience', id: number, title: string } }> | null } };

export type CurrentHostUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentHostUserQuery = { __typename?: 'Query', currentHostUser: { __typename?: 'HostUser', id: number, number?: string | null, cep?: string | null, facebook?: string | null, linkedin?: string | null, instagram?: string | null, cpf: string, birthDate: any, state: string, city: string, neighborhood: string, telephone: string, educationLevel: HostUserEducationLevel, street?: string | null, educationalInstitution: string, isVolunteer: boolean, isApproved: boolean, bio: string, specialization: { __typename?: 'Specialization', id: number }, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean } } };

export type HostUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type HostUserQuery = { __typename?: 'Query', hostUser: { __typename?: 'HostUser', id: number, number?: string | null, cep?: string | null, facebook?: string | null, linkedin?: string | null, instagram?: string | null, cpf: string, birthDate: any, state: string, city: string, neighborhood: string, telephone: string, educationLevel: HostUserEducationLevel, street?: string | null, educationalInstitution: string, isVolunteer: boolean, isApproved: boolean, bio: string, specialization: { __typename?: 'Specialization', id: number }, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean } } };

export type ListHostsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListHostsQuery = { __typename?: 'Query', hostUsers: Array<{ __typename?: 'HostUser', id: number, user: { __typename?: 'User', name: string } }> };

export type RemoveHostMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveHostMutation = { __typename?: 'Mutation', removeHostUser: { __typename: 'HostUser' } };

export type UpdateHostUserMutationVariables = Exact<{
  input: UpdateHostUserInput;
}>;


export type UpdateHostUserMutation = { __typename?: 'Mutation', updateHostUser: { __typename: 'HostUser' } };

export type ListItinerariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListItinerariesQuery = { __typename?: 'Query', itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }> };

export type CreateJitsiJwtMutationVariables = Exact<{
  input: CreateJwtJitsiInput;
}>;


export type CreateJitsiJwtMutation = { __typename?: 'Mutation', createJWTJitsi: string };

export type JitsiDestroyRoomMutationVariables = Exact<{
  input: JitsiDestroyRoom;
}>;


export type JitsiDestroyRoomMutation = { __typename?: 'Mutation', jitsiDestroyRoom: string };

export type CountNotificationsQueryVariables = Exact<{
  input?: InputMaybe<FilterNotificationInput>;
}>;


export type CountNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename: 'Notification' }> };

export type ListNotificationsQueryVariables = Exact<{
  input?: InputMaybe<FilterNotificationInput>;
}>;


export type ListNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: number, createdAt: any, title: string, text: string, url?: string | null, readed: boolean }> };

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename: 'Notification' } };

export type CreateScheduleMutationVariables = Exact<{
  input: CreateScheduleInput;
}>;


export type CreateScheduleMutation = { __typename?: 'Mutation', createSchedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, limit: number } };

export type FinishScheduleMutationVariables = Exact<{
  input: GraphQlRelationInput;
}>;


export type FinishScheduleMutation = { __typename?: 'Mutation', finishSchedule: boolean };

export type ListSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSchedulesQuery = { __typename?: 'Query', schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, occurredEndDate?: any | null, videoURL: string, limit: number, experience: { __typename?: 'Experience', id: number, title: string, imageSrcIndex: number, imageSrcs: Array<string>, subtitle: string }, hostUser: { __typename?: 'HostUser', id: number } }> };

export type RemoveScheduleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveScheduleMutation = { __typename?: 'Mutation', removeSchedule: { __typename: 'Schedule' } };

export type ScheduleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ScheduleQuery = { __typename?: 'Query', schedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, limit: number, hostUser: { __typename?: 'HostUser', id: number }, experience: { __typename?: 'Experience', id: number, specialization: { __typename?: 'Specialization', id: number } } } };

export type UpdateScheduleMutationVariables = Exact<{
  input: UpdateScheduleInput;
}>;


export type UpdateScheduleMutation = { __typename?: 'Mutation', updateSchedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any } };

export type ListSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'Setting', id: number, name: string, value: string }> };

export type SettingsIosQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsIosQuery = { __typename?: 'Query', settingsIOS: Array<{ __typename?: 'Setting', id: number, name: string, value: string }> };

export type ListSpecializationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSpecializationsQuery = { __typename?: 'Query', specializations: Array<{ __typename?: 'Specialization', id: number, name: string }> };

export type CreateStudentUserTrialMutationVariables = Exact<{
  input: CreateStudentUserTrialInput;
}>;


export type CreateStudentUserTrialMutation = { __typename?: 'Mutation', createStudentUserTrial: { __typename?: 'StudentUserByIntegration', id: number, name: string, email: string, cpf: string, birthDate?: any | null, telephone: string } };

export type CreateStudentUserMutationVariables = Exact<{
  input: CreateStudentUserInput;
}>;


export type CreateStudentUserMutation = { __typename?: 'Mutation', createStudentUser: { __typename: 'StudentUser' } };

export type CurrentStudentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentStudentUserQuery = { __typename?: 'Query', currentStudentUser: { __typename?: 'StudentUser', id: number, number?: string | null, cep?: string | null, ethnicity: StudentUserEthnicity, facebook?: string | null, linkedin?: string | null, instagram?: string | null, familyIncome: StudentUserFamilyIncome, birthDate?: any | null, hoursExperienced: number, state: string, city: string, neighborhood: string, telephone: string, courseType: StudentUserCourseType, street?: string | null, residenceTestAt?: any | null, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, isActive: boolean, integration?: { __typename?: 'Integration', name: string } | null }, specialization?: { __typename?: 'Specialization', id: number, name: string } | null, allowedStudent: { __typename?: 'AllowedStudent', cpf: string } } };

export type ListStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStudentsQuery = { __typename?: 'Query', studentUsers: Array<{ __typename?: 'StudentUser', id: number, user: { __typename?: 'User', name: string } }> };

export type RemoveStudentMutationVariables = Exact<{
  input: DeleteStudentUserInput;
}>;


export type RemoveStudentMutation = { __typename?: 'Mutation', removeStudentUser: { __typename: 'StudentUser' } };

export type StudentUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StudentUserQuery = { __typename?: 'Query', studentUser: { __typename?: 'StudentUser', id: number, number?: string | null, cep?: string | null, ethnicity: StudentUserEthnicity, facebook?: string | null, linkedin?: string | null, instagram?: string | null, familyIncome: StudentUserFamilyIncome, birthDate?: any | null, hoursExperienced: number, state: string, city: string, neighborhood: string, telephone: string, courseType: StudentUserCourseType, residenceTestAt?: any | null, street?: string | null, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, isActive: boolean }, allowedStudent: { __typename?: 'AllowedStudent', cpf: string } } };

export type UpdateStudentUserMutationVariables = Exact<{
  input: UpdateStudentUserInput;
}>;


export type UpdateStudentUserMutation = { __typename?: 'Mutation', updateStudentUser: { __typename: 'StudentUser' } };

export type CreateItemMondayMutationVariables = Exact<{
  input: CreateItemMondayInput;
}>;


export type CreateItemMondayMutation = { __typename?: 'Mutation', createItemMonday: { __typename?: 'ItemMonday', id: string } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, integration?: { __typename?: 'Integration', name: string } | null, studentUser?: { __typename?: 'StudentUser', id: number, courseType: StudentUserCourseType } | null, hostUser?: { __typename?: 'HostUser', id: number } | null } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: { __typename: 'User' } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginUserInput!) {
  loginUser(loginUserInput: $input) {
    accessToken
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginUserGQL extends Apollo.Mutation<LoginUserMutation, LoginUserMutationVariables> {
    document = LoginUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPasswordRecoveryDocument = gql`
    mutation RequestPasswordRecovery($input: RequestPasswordRecoveryInput!) {
  requestPasswordRecovery(requestPasswordRecoveryInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPasswordRecoveryGQL extends Apollo.Mutation<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables> {
    document = RequestPasswordRecoveryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMaterialDocument = gql`
    mutation RemoveMaterial($experienceId: Int!) {
  removeMaterial(experienceId: $experienceId) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMaterialGQL extends Apollo.Mutation<RemoveMaterialMutation, RemoveMaterialMutationVariables> {
    document = RemoveMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadMaterialDocument = gql`
    mutation UploadMaterial($file: Upload!, $experienceId: Int!) {
  uploadMaterial(file: $file, experienceId: $experienceId) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadMaterialGQL extends Apollo.Mutation<UploadMaterialMutation, UploadMaterialMutationVariables> {
    document = UploadMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  uploadImage(file: $file) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadImageGQL extends Apollo.Mutation<UploadImageMutation, UploadImageMutationVariables> {
    document = UploadImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($file: Upload!, $userId: Int) {
  uploadUserAvatar(file: $file, userId: $userId) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadUserAvatarGQL extends Apollo.Mutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables> {
    document = UploadUserAvatarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnrollmentRecordedDocument = gql`
    mutation CreateEnrollmentRecorded($input: CreateEnrollmentRecordedInput!) {
  createEnrollmentRecorded(createEnrollmentRecordedInput: $input) {
    id
    rating
    studentUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnrollmentRecordedGQL extends Apollo.Mutation<CreateEnrollmentRecordedMutation, CreateEnrollmentRecordedMutationVariables> {
    document = CreateEnrollmentRecordedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnrollmentDocument = gql`
    mutation CreateEnrollment($input: CreateEnrollmentInput!) {
  createEnrollment(createEnrollmentInput: $input) {
    id
    rating
    studentUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnrollmentGQL extends Apollo.Mutation<CreateEnrollmentMutation, CreateEnrollmentMutationVariables> {
    document = CreateEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnrollmentDocument = gql`
    query Enrollment($id: Int!) {
  enrollment(id: $id) {
    id
    createdAt
    rating
    ratingComment
    schedule {
      experience {
        title
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnrollmentGQL extends Apollo.Query<EnrollmentQuery, EnrollmentQueryVariables> {
    document = EnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEnrollmentsDocument = gql`
    query ListEnrollments {
  enrollments {
    id
    wasPresent
    viewType
    schedule {
      id
      startDate
      endDate
      videoURL
      occurredEndDate
      experience {
        id
        title
        subtitle
        imageSrcs
        imageSrcIndex
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEnrollmentsGQL extends Apollo.Query<ListEnrollmentsQuery, ListEnrollmentsQueryVariables> {
    document = ListEnrollmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveEnrollmentDocument = gql`
    mutation RemoveEnrollment($id: Int!) {
  removeEnrollment(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveEnrollmentGQL extends Apollo.Mutation<RemoveEnrollmentMutation, RemoveEnrollmentMutationVariables> {
    document = RemoveEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnrollmentDocument = gql`
    mutation UpdateEnrollment($input: UpdateEnrollmentInput!) {
  updateEnrollment(updateEnrollmentInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnrollmentGQL extends Apollo.Mutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables> {
    document = UpdateEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateExperienceDocument = gql`
    mutation CreateExperience($input: CreateExperienceInput!) {
  createExperience(createExperienceInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateExperienceGQL extends Apollo.Mutation<CreateExperienceMutation, CreateExperienceMutationVariables> {
    document = CreateExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceFindCollectedDocument = gql`
    query ExperienceFindCollected($id: Int!) {
  findCollected(id: $id) {
    id
    title
    subtitle
    imageSrcs
    imageSrcIndex
    description
    tags
    material
    videoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceFindCollectedGQL extends Apollo.Query<ExperienceFindCollectedQuery, ExperienceFindCollectedQueryVariables> {
    document = ExperienceFindCollectedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceWithoutEnrollmentsDocument = gql`
    query ExperienceWithoutEnrollments($id: Int!, $filterSchedule: FilterExperienceSchedulesInput!) {
  experience(id: $id) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    jobScore
    averageSalary
    material
    videoUrl
    rating {
      value
      count
    }
    specialization {
      id
      name
    }
    itinerary {
      id
      name
    }
    schedules(filters: $filterSchedule) {
      id
      startDate
      endDate
      videoURL
      occurredEndDate
      hostUser {
        id
        user {
          name
          avatarSrc
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceWithoutEnrollmentsGQL extends Apollo.Query<ExperienceWithoutEnrollmentsQuery, ExperienceWithoutEnrollmentsQueryVariables> {
    document = ExperienceWithoutEnrollmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceDocument = gql`
    query Experience($id: Int!, $filterSchedule: FilterExperienceSchedulesInput!) {
  experience(id: $id) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    tags
    jobScore
    averageSalary
    material
    videoUrl
    rating {
      value
      count
    }
    specialization {
      id
      name
    }
    itinerary {
      id
      name
    }
    schedules(filters: $filterSchedule) {
      id
      startDate
      endDate
      videoURL
      occurredEndDate
      enrollments {
        id
        rating
        studentUser {
          id
        }
      }
      hostUser {
        id
        bio
        user {
          name
          avatarSrc
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceGQL extends Apollo.Query<ExperienceQuery, ExperienceQueryVariables> {
    document = ExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListExperiencesDocument = gql`
    query ListExperiences($filters: FilterExperienceInput, $orders: OrderExperienceInput) {
  experiences(filters: $filters, orders: $orders) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    jobScore
    averageSalary
    videoUrl
    material
    itinerary {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListExperiencesGQL extends Apollo.Query<ListExperiencesQuery, ListExperiencesQueryVariables> {
    document = ListExperiencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RaioXDocument = gql`
    query RaioX {
  raiox {
    youMayAlsoLike {
      id
      title
    }
    experiencesLived {
      schedule {
        startDate
        hostUser {
          user {
            name
          }
        }
        experience {
          specialization {
            id
            name
          }
          id
          title
          itinerary {
            name
          }
        }
      }
      viewType
      rating
      createdAt
    }
    latentAreas
    raioXMinEnrollment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RaioXGQL extends Apollo.Query<RaioXQuery, RaioXQueryVariables> {
    document = RaioXDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecommendedExperienceDocument = gql`
    query RecommendedExperience {
  recommendedExperiences {
    id
    title
    subtitle
    imageSrcs
    imageSrcIndex
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecommendedExperienceGQL extends Apollo.Query<RecommendedExperienceQuery, RecommendedExperienceQueryVariables> {
    document = RecommendedExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveExperienceDocument = gql`
    mutation RemoveExperience($id: Int!) {
  removeExperience(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveExperienceGQL extends Apollo.Mutation<RemoveExperienceMutation, RemoveExperienceMutationVariables> {
    document = RemoveExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateExperienceDocument = gql`
    mutation UpdateExperience($input: UpdateExperienceInput!) {
  updateExperience(updateExperienceInput: $input) {
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateExperienceGQL extends Apollo.Mutation<UpdateExperienceMutation, UpdateExperienceMutationVariables> {
    document = UpdateExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHostUserDocument = gql`
    mutation CreateHostUser($input: CreateHostUserInput!) {
  createHostUser(createHostUserInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHostUserGQL extends Apollo.Mutation<CreateHostUserMutation, CreateHostUserMutationVariables> {
    document = CreateHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentHostSchedulesDocument = gql`
    query CurrentHostSchedules {
  currentHostUser {
    schedules {
      id
      startDate
      endDate
      videoURL
      experience {
        id
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentHostSchedulesGQL extends Apollo.Query<CurrentHostSchedulesQuery, CurrentHostSchedulesQueryVariables> {
    document = CurrentHostSchedulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentHostUserDocument = gql`
    query CurrentHostUser {
  currentHostUser {
    id
    number
    cep
    facebook
    linkedin
    instagram
    cpf
    birthDate
    state
    city
    neighborhood
    telephone
    educationLevel
    street
    specialization {
      id
    }
    educationalInstitution
    isVolunteer
    isApproved
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
    }
    bio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentHostUserGQL extends Apollo.Query<CurrentHostUserQuery, CurrentHostUserQueryVariables> {
    document = CurrentHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HostUserDocument = gql`
    query HostUser($id: Int!) {
  hostUser(id: $id) {
    id
    number
    cep
    facebook
    linkedin
    instagram
    cpf
    birthDate
    state
    city
    neighborhood
    telephone
    educationLevel
    street
    specialization {
      id
    }
    educationalInstitution
    isVolunteer
    isApproved
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
    }
    bio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HostUserGQL extends Apollo.Query<HostUserQuery, HostUserQueryVariables> {
    document = HostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListHostsDocument = gql`
    query ListHosts {
  hostUsers {
    id
    user {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListHostsGQL extends Apollo.Query<ListHostsQuery, ListHostsQueryVariables> {
    document = ListHostsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveHostDocument = gql`
    mutation RemoveHost($id: Int!) {
  removeHostUser(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveHostGQL extends Apollo.Mutation<RemoveHostMutation, RemoveHostMutationVariables> {
    document = RemoveHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHostUserDocument = gql`
    mutation UpdateHostUser($input: UpdateHostUserInput!) {
  updateHostUser(updateHostUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHostUserGQL extends Apollo.Mutation<UpdateHostUserMutation, UpdateHostUserMutationVariables> {
    document = UpdateHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListItinerariesDocument = gql`
    query ListItineraries {
  itineraries {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListItinerariesGQL extends Apollo.Query<ListItinerariesQuery, ListItinerariesQueryVariables> {
    document = ListItinerariesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJitsiJwtDocument = gql`
    mutation CreateJitsiJWT($input: CreateJWTJitsiInput!) {
  createJWTJitsi(createJWTJitsiInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJitsiJwtGQL extends Apollo.Mutation<CreateJitsiJwtMutation, CreateJitsiJwtMutationVariables> {
    document = CreateJitsiJwtDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JitsiDestroyRoomDocument = gql`
    mutation JitsiDestroyRoom($input: JitsiDestroyRoom!) {
  jitsiDestroyRoom(jitsiDestroyRoom: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JitsiDestroyRoomGQL extends Apollo.Mutation<JitsiDestroyRoomMutation, JitsiDestroyRoomMutationVariables> {
    document = JitsiDestroyRoomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CountNotificationsDocument = gql`
    query CountNotifications($input: FilterNotificationInput) {
  notifications(filters: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CountNotificationsGQL extends Apollo.Query<CountNotificationsQuery, CountNotificationsQueryVariables> {
    document = CountNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListNotificationsDocument = gql`
    query ListNotifications($input: FilterNotificationInput) {
  notifications(filters: $input) {
    id
    createdAt
    title
    text
    url
    readed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListNotificationsGQL extends Apollo.Query<ListNotificationsQuery, ListNotificationsQueryVariables> {
    document = ListNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($input: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNotificationGQL extends Apollo.Mutation<UpdateNotificationMutation, UpdateNotificationMutationVariables> {
    document = UpdateNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($input: CreateScheduleInput!) {
  createSchedule(createScheduleInput: $input) {
    id
    startDate
    endDate
    videoURL
    limit
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateScheduleGQL extends Apollo.Mutation<CreateScheduleMutation, CreateScheduleMutationVariables> {
    document = CreateScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishScheduleDocument = gql`
    mutation FinishSchedule($input: GraphQLRelationInput!) {
  finishSchedule(schedule: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishScheduleGQL extends Apollo.Mutation<FinishScheduleMutation, FinishScheduleMutationVariables> {
    document = FinishScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSchedulesDocument = gql`
    query ListSchedules {
  schedules {
    id
    startDate
    endDate
    occurredEndDate
    videoURL
    limit
    experience {
      id
      title
      imageSrcIndex
      imageSrcs
      subtitle
    }
    hostUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSchedulesGQL extends Apollo.Query<ListSchedulesQuery, ListSchedulesQueryVariables> {
    document = ListSchedulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveScheduleDocument = gql`
    mutation RemoveSchedule($id: Int!) {
  removeSchedule(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveScheduleGQL extends Apollo.Mutation<RemoveScheduleMutation, RemoveScheduleMutationVariables> {
    document = RemoveScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScheduleDocument = gql`
    query Schedule($id: Int!) {
  schedule(id: $id) {
    id
    startDate
    endDate
    videoURL
    limit
    hostUser {
      id
    }
    experience {
      id
      specialization {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduleGQL extends Apollo.Query<ScheduleQuery, ScheduleQueryVariables> {
    document = ScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateScheduleDocument = gql`
    mutation UpdateSchedule($input: UpdateScheduleInput!) {
  updateSchedule(updateScheduleInput: $input) {
    id
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateScheduleGQL extends Apollo.Mutation<UpdateScheduleMutation, UpdateScheduleMutationVariables> {
    document = UpdateScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSettingsDocument = gql`
    query ListSettings {
  settings {
    id
    name
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSettingsGQL extends Apollo.Query<ListSettingsQuery, ListSettingsQueryVariables> {
    document = ListSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SettingsIosDocument = gql`
    query SettingsIOS {
  settingsIOS {
    id
    name
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingsIosGQL extends Apollo.Query<SettingsIosQuery, SettingsIosQueryVariables> {
    document = SettingsIosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSpecializationsDocument = gql`
    query ListSpecializations {
  specializations {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSpecializationsGQL extends Apollo.Query<ListSpecializationsQuery, ListSpecializationsQueryVariables> {
    document = ListSpecializationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStudentUserTrialDocument = gql`
    mutation CreateStudentUserTrial($input: CreateStudentUserTrialInput!) {
  createStudentUserTrial(args: $input) {
    id
    name
    email
    cpf
    birthDate
    telephone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStudentUserTrialGQL extends Apollo.Mutation<CreateStudentUserTrialMutation, CreateStudentUserTrialMutationVariables> {
    document = CreateStudentUserTrialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStudentUserDocument = gql`
    mutation CreateStudentUser($input: CreateStudentUserInput!) {
  createStudentUser(createStudentUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStudentUserGQL extends Apollo.Mutation<CreateStudentUserMutation, CreateStudentUserMutationVariables> {
    document = CreateStudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentStudentUserDocument = gql`
    query CurrentStudentUser {
  currentStudentUser {
    id
    number
    cep
    ethnicity
    facebook
    linkedin
    instagram
    familyIncome
    birthDate
    hoursExperienced
    state
    city
    neighborhood
    telephone
    courseType
    street
    residenceTestAt
    itineraries {
      id
      name
    }
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
      isActive
      integration {
        name
      }
    }
    specialization {
      id
      name
    }
    allowedStudent {
      cpf
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentStudentUserGQL extends Apollo.Query<CurrentStudentUserQuery, CurrentStudentUserQueryVariables> {
    document = CurrentStudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListStudentsDocument = gql`
    query ListStudents {
  studentUsers {
    id
    user {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListStudentsGQL extends Apollo.Query<ListStudentsQuery, ListStudentsQueryVariables> {
    document = ListStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveStudentDocument = gql`
    mutation RemoveStudent($input: DeleteStudentUserInput!) {
  removeStudentUser(deleteStudentUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveStudentGQL extends Apollo.Mutation<RemoveStudentMutation, RemoveStudentMutationVariables> {
    document = RemoveStudentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentUserDocument = gql`
    query StudentUser($id: Int!) {
  studentUser(id: $id) {
    id
    number
    cep
    ethnicity
    facebook
    linkedin
    instagram
    familyIncome
    birthDate
    hoursExperienced
    state
    city
    neighborhood
    telephone
    courseType
    residenceTestAt
    itineraries {
      id
      name
    }
    street
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
      isActive
    }
    allowedStudent {
      cpf
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentUserGQL extends Apollo.Query<StudentUserQuery, StudentUserQueryVariables> {
    document = StudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStudentUserDocument = gql`
    mutation UpdateStudentUser($input: UpdateStudentUserInput!) {
  updateStudentUser(updateStudentUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStudentUserGQL extends Apollo.Mutation<UpdateStudentUserMutation, UpdateStudentUserMutationVariables> {
    document = UpdateStudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateItemMondayDocument = gql`
    mutation CreateItemMonday($input: CreateItemMondayInput!) {
  createItemMonday(createItemMondayInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateItemMondayGQL extends Apollo.Mutation<CreateItemMondayMutation, CreateItemMondayMutationVariables> {
    document = CreateItemMondayDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    avatarSrc
    name
    email
    role
    gender
    isEmailVerified
    integration {
      name
    }
    studentUser {
      id
      courseType
    }
    hostUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: Int!) {
  removeUser(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(updateUserInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    