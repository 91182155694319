import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { UserRole } from 'src/generated/graphql';

@Component({
  selector: 'user-pop-over-profile',
  templateUrl: './user-pop-over-profile.component.html',
  styleUrls: ['./user-pop-over-profile.component.scss'],
})
export class UserPopOverProfileComponent implements OnInit {
  userRole = UserRole;

  constructor(
    private router: Router,
    public translations: TranslationsLayoutService,
    private popOverController: PopoverController,
    public userService: UserService
  ) {}

  ngOnInit() {}

  navigate(route: string) {
    this.popOverController.dismiss();
    this.router.navigate([route]);
  }

  logoff() {
    localStorage.setItem('seren-token', '');
    this.userService.updateCurrentUser(null);
    this.popOverController.dismiss();
    this.router.navigate(['/auth/login']);
  }
}
