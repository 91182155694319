import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-form-title',
  templateUrl: './shared-form-title.component.html',
  styleUrls: ['./shared-form-title.component.scss'],
})
export class SharedFormTitleComponent implements OnInit {
  @Input() title: string = '';
  @Input() required: boolean = false;

  constructor() {}

  ngOnInit() {}
}
