import { Injectable } from '@angular/core';
import { IonicSafeString, ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastOptions: ToastOptions = {
    message: '',
    duration: 4000,
    position: 'top',
    cssClass: '--toast',
    animated: true,
    mode: 'ios',
  };

  private checkMark = `<ion-icon style="font-size:24px;color:white;--ionicon-stroke-width: 80px;margin-right: 5px;" name="checkmark-outline"></ion-icon>`;
  private errorMark = `<ion-icon style="font-size:24px;color:white;--ionicon-stroke-width: 80px;margin-right: 5px;" name="close-outline"></ion-icon>`;

  constructor(private toastController: ToastController) {
    //preloading to work without internet connection
    this.preload();
    // this.success('sasi');
  }

  async preload() {
    await this.toastController.create(this.toastOptions);
  }

  async success(message: string) {
    this.toastOptions.cssClass = '--toast--success';

    const toast = await this.toastController.create({
      ...this.toastOptions,
      message: new IonicSafeString(this.checkMark + message),
    });
    toast.present();
  }

  async error(message: string) {
    this.toastOptions.cssClass = '--toast--error';

    const toast = await this.toastController.create({
      ...this.toastOptions,
      message: new IonicSafeString(this.errorMark + message),
    });
    toast.present();
  }
}
