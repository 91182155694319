import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLayoutService } from 'src/app/core/services/router/router-layout.service';

@Component({
  selector: 'shared-navigation-button',
  templateUrl: './shared-navigation-button.component.html',
  styleUrls: ['./shared-navigation-button.component.scss'],
})
export class SharedNavigationButtonComponent implements OnInit {
  @Input() iconSrc: string;
  @Input() text: string;
  @Input() route: string;

  constructor(
    private router: Router,
    public routerLayoutService: RouterLayoutService
  ) {}

  ngOnInit() {}

  navigate() {
    if (!this.route) return;
    this.router.navigate([this.route]);
  }
}
