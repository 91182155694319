import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "shared-tool-tip",
  templateUrl: "./shared-tool-tip.component.html",
  styleUrls: ["./shared-tool-tip.component.scss"],
})
export class SharedToolTipComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit() {}
}
