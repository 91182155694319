import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/user/services/user/user.service';
import { CurrentUserGQL } from 'src/generated/graphql';
import { LayoutService } from '../services/layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanLoad {
  token: string;

  constructor(
    private userService: UserService,
    private currentUserGQL: CurrentUserGQL,
    private layoutService: LayoutService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.url[0].path === 'update-password') {
      this.layoutService.$isToShowMenus.next(false);
    } else if (route.url[0].path === 'home') {
      this.layoutService.$isToShowMenus.next(true);
    }

    return this.checkIsLoggedIn();
  }

  async canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkIsLoggedIn();
  }

  private async checkIsLoggedIn(): Promise<boolean> {
    try {
      if (this.hasUserInService() === true) return true;
      if (this.hasTokenInURL() === true) {
        localStorage.setItem('seren-token', this.token);
        // return true;
      }
      if (await this.updateCurrentUser()) return true;

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  hasUserInService() {
    return this.userService.hasUser();
  }

  hasTokenInURL() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('t') === false) {
      return false;
    }

    const token = urlParams.get('t');

    if (token === null || token === '' || token === undefined) return false;

    this.token = token;

    return true;
  }

  async updateCurrentUser() {
    try {
      const result = await firstValueFrom(this.currentUserGQL.fetch());

      if (!result.data?.currentUser?.id) return false;

      this.userService.updateCurrentUser({
        currentUser: result.data.currentUser,
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
