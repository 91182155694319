import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
  selector: "[skeleton]",
})
export class SkeletonDirective implements OnChanges {
  @Input() disabledSkeleton = false;
  @Input() disabledChildrenSkeleton = false;
  @Input() colorSkeleton = "";

  constructor(private elementRef: ElementRef<HTMLElement>) {
    this.elementRef.nativeElement.classList.add("--skeleton");
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.disabledSkeleton?.currentValue === true) {
      this.elementRef.nativeElement.classList.remove("--skeleton");
    } else {
      this.elementRef.nativeElement.classList.add("--skeleton");
    }

    if (simpleChanges.disabledChildrenSkeleton?.currentValue === true) {
      this.elementRef.nativeElement.classList.add("--skeleton-children-disabled");
    } else {
      this.elementRef.nativeElement.classList.remove("--skeleton-children-disabled");
    }
    if (simpleChanges.colorSkeleton?.currentValue) {
      this.elementRef.nativeElement.style.background = simpleChanges.colorSkeleton.currentValue;
    } else {
      this.elementRef.nativeElement.style.background = "";
    }
  }
}
