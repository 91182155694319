export enum EnvironmentName {
  production,
  staging,
  local,
}

export interface IEnvironment {
  name: EnvironmentName;
  url: string;
  s3Url: string;
  mediaURL: string;
  version: string;
}
