import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AutocompleteTypes } from '@ionic/core';
import { BrMaskModel } from 'br-mask';

@Component({
  selector: 'shared-form-input',
  templateUrl: './shared-form-input.component.html',
  styleUrls: ['./shared-form-input.component.scss'],
})
export class SharedFormInputComponent implements OnInit {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() type: 'text' | 'password' | 'number' | 'tel' | 'email' | 'date' =
    'text';
  @Input() title: string = '';
  @Input() placeholder: string = '';
  @Input() helpText: string;
  @Input() toolTipText: string;
  @Input() brMasker?: BrMaskModel;
  invalid: boolean = false;

  @Input() autocomplete: AutocompleteTypes = 'on';

  @Output() keyPressed = new EventEmitter<KeyboardEvent>();

  ngOnInit() {}

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
