import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrMaskerModule } from 'br-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaterialModule } from '../material.module';
import { SharedModalContactComponent } from './components/modals/shared-modal-contact/shared-modal-contact.component';
import { SharedModalCreateExperienceComponent } from './components/modals/shared-modal-create-experience/shared-modal-create-experience.component';
import { SharedModalDeleteExperienceComponent } from './components/modals/shared-modal-delete-experience/shared-modal-delete-experience.component';
import { SharedModalRaioXComponent } from './components/modals/shared-modal-raio-x/shared-modal-raio-x.component';
import { SharedModalRateExperienceComponent } from './components/modals/shared-modal-rate-experience/shared-modal-rate-experience.component';
import { SharedModalRememberRatingComponent } from './components/modals/shared-modal-remember-rating/shared-modal-remember-rating.component';
import { SharedBannerSecurityComponent } from './components/shared-banner-security/shared-banner-security.component';
import { SharedBannerComponent } from './components/shared-banner/shared-banner.component';
import { SharedButtonExperienceComponent } from './components/shared-button-experience/shared-button-experience.component';
import { SharedButtonWhatsappComponent } from './components/shared-button-whatsapp/shared-button-whatsapp.component';
import { SharedButtonComponent } from './components/shared-button/shared-button.component';
import { SharedFormAutocompleteComponent } from './components/shared-form-autocomplete/shared-form-autocomplete.component';
import { SharedFormBannerImageComponent } from './components/shared-form-banner-image/shared-form-banner-image.component';
import { SharedFormChecklistComponent } from './components/shared-form-checklist/shared-form-checklist.component';
import { SharedFormComboboxComponent } from './components/shared-form-combobox/shared-form-combobox.component';
import { SharedFormErrorComponent } from './components/shared-form-error/shared-form-error.component';
import { SharedFormFooterComponent } from './components/shared-form-footer/shared-form-footer.component';
import { SharedFormInputFileComponent } from './components/shared-form-input-file/shared-form-input-file.component';
import { SharedFormInputComponent } from './components/shared-form-input/shared-form-input.component';
import { SharedFormInsertListComponent } from './components/shared-form-insert-list/shared-form-insert-list.component';
import { SharedFormInsertSelectComponent } from './components/shared-form-insert-select/shared-form-insert-select.component';
import { SharedFormRatingComponent } from './components/shared-form-rating/shared-form-rating.component';
import { SharedFormRequiredIconComponent } from './components/shared-form-required-icon/shared-form-required-icon.component';
import { SharedFormSelectComponent } from './components/shared-form-select/shared-form-select.component';
import { SharedFormStatusIconComponent } from './components/shared-form-status-icon/shared-form-status-icon.component';
import { SharedFormStepFooterComponent } from './components/shared-form-step-footer/shared-form-step-footer.component';
import { SharedFormTextareaComponent } from './components/shared-form-textarea/shared-form-textarea.component';
import { SharedFormTimeInputComponent } from './components/shared-form-time-input/shared-form-time-input.component';
import { SharedFormTitleComponent } from './components/shared-form-title/shared-form-title.component';
import { SharedFormToggleListComponent } from './components/shared-form-toggle-list/shared-form-toggle-list.component';
import { SharedFormToggleComponent } from './components/shared-form-toggle/shared-form-toggle.component';
import { SharedLineComponent } from './components/shared-line/shared-line.component';
import { SharedNavigationButtonComponent } from './components/shared-navigation-button/shared-navigation-button.component';
import { SharedNoContentComponent } from './components/shared-no-content/shared-no-content.component';
import { SharedNotificationButtonComponent } from './components/shared-notification-button/shared-notification-button.component';
import { SharedProgressBarComponent } from './components/shared-progress-bar/shared-progress-bar.component';
import { SharedTitleComponent } from './components/shared-title/shared-title.component';
import { SharedToolTipComponent } from './components/shared-tool-tip/shared-tool-tip.component';
import { SkeletonDirective } from './directives/skeleton/skeleton.directive';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    AngularSvgIconModule,
    MaterialModule,
    BrMaskerModule,
    NgxMaterialTimepickerModule,
  ],
  declarations: [
    SharedFormInputComponent,
    SharedButtonComponent,
    SharedLineComponent,
    SharedFormToggleComponent,
    SharedFormStatusIconComponent,
    SharedFormRequiredIconComponent,
    SharedNotificationButtonComponent,
    SharedNavigationButtonComponent,
    SharedBannerComponent,
    SharedButtonExperienceComponent,
    SharedTitleComponent,
    SharedFormBannerImageComponent,
    SharedFormTextareaComponent,
    SharedFormInsertListComponent,
    SharedFormStepFooterComponent,
    SharedFormSelectComponent,
    SafePipe,
    SharedFormToggleListComponent,
    SharedBannerSecurityComponent,
    SharedToolTipComponent,
    CreditCardMaskPipe,
    SharedFormFooterComponent,
    SharedModalCreateExperienceComponent,
    SharedModalContactComponent,
    SharedModalDeleteExperienceComponent,
    SharedModalRateExperienceComponent,
    SharedFormRatingComponent,
    SharedModalRateExperienceComponent,
    SkeletonDirective,
    SharedFormTimeInputComponent,
    SharedFormComboboxComponent,
    SharedFormInsertSelectComponent,
    SharedFormChecklistComponent,
    SharedNoContentComponent,
    SharedButtonWhatsappComponent,
    SharedFormErrorComponent,
    SharedModalRememberRatingComponent,
    SharedFormAutocompleteComponent,
    SharedFormTitleComponent,
    SharedModalRaioXComponent,
    SharedFormInputFileComponent,
    SharedProgressBarComponent,
  ],
  exports: [
    SharedFormInputComponent,
    SharedButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    SharedLineComponent,
    SharedFormToggleComponent,
    SharedFormStatusIconComponent,
    SharedFormRequiredIconComponent,
    SharedNotificationButtonComponent,
    SharedNavigationButtonComponent,
    AngularSvgIconModule,
    SharedBannerComponent,
    SharedButtonExperienceComponent,
    SharedTitleComponent,
    SharedFormBannerImageComponent,
    SharedFormTextareaComponent,
    SharedFormInsertListComponent,
    SharedFormStepFooterComponent,
    SharedFormSelectComponent,
    SafePipe,
    SharedFormToggleListComponent,
    SharedBannerSecurityComponent,
    SharedToolTipComponent,
    CreditCardMaskPipe,
    SharedFormFooterComponent,
    SharedModalCreateExperienceComponent,
    SharedModalContactComponent,
    SharedModalDeleteExperienceComponent,
    SharedModalRateExperienceComponent,
    SharedFormRatingComponent,
    SharedModalRateExperienceComponent,
    SkeletonDirective,
    SharedFormTimeInputComponent,
    SharedFormComboboxComponent,
    SharedFormInsertSelectComponent,
    SharedFormChecklistComponent,
    SharedNoContentComponent,
    SharedButtonWhatsappComponent,
    SharedFormErrorComponent,
    SharedModalRememberRatingComponent,
    SharedFormAutocompleteComponent,
    SharedFormTitleComponent,
    SharedModalRaioXComponent,
    SharedFormInputFileComponent,
    SharedProgressBarComponent,
  ],
})
export class SharedModule {}
